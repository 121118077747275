import AccederSection from './AccederSection';
import EtapaSection from './EtapaSection';
import FuncionamientoSection from './FuncionamientoSection';
import ExplicacionSection from './FuncionamientoSection/ExplicacionSection';
import InformateSection from './InformateSection';
import MapaSection from './MapaSection';
import QueEsSection from './QueEsSection';
import ResultadosSection from './ResultadosSection';
import SolicitarApoyoSection from './SolicitarApoyoSection';
import TestimonialesSection from './TestimonialesSection';
import ProcesoSection from './ProcesoSection';
import Waves from './Waves';

const Home = () => (
  <div id="inicio" className="overflow-x-hidden">
    <Waves />
    <QueEsSection />
    <FuncionamientoSection />
    {/*<ProcesoSection />*/}
    <ExplicacionSection />
    <EtapaSection />
    <AccederSection />
    <MapaSection />
    <SolicitarApoyoSection />
    <InformateSection />
  </div>
);

export default Home;
