import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

/**
 * Hook que permite acceder a métodos personalizados utilizando
 * el Provider de Recaptcha.
 */
const useRecaptcha = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  /**
   * Función que obtiene el token de autenticación de Recaptcha.
   */
  const getRecaptchaToken = async () => {
    // Si Recaptcha no ha sido inicializada no se ejecuta la función
    if (!executeRecaptcha) {
      return null;
    }
    const token = await executeRecaptcha();
    return token;
  };

  return { getRecaptchaToken };
};

export default useRecaptcha;
