import { Card, CardContent } from '@mui/material';
import clsx from 'clsx';

const CardResult = ({ item, wrap }) => (
  <Card
    sx={{
      display: `flex`,
      flexDirection: `column`,
      opacity: 1,
      boxShadow: `0px 15px 28px #67A7BE29`,
      borderRadius: `30px`,
      maxWidth: `375px`,
      minHeight: `176px`,
      width: `375px`,
    }}
  >
    <CardContent
    >
      <div className="grid grid-cols-3 mx-0">
        <div style={{ marginRight: item?.mrIcon || '0px', marginLeft: item?.mlIcon || '0px' }}>{item?.icon}</div>
        <div
            style={{ lineHeight: `1` }}
            className={clsx(
              `text-primary-alternative-light font-montserrat-extrabold xl2:text-[38px] sm:text-[35px] whitespace-normal text-[28px] flex items-center col-span-2`,
              {
                [`whitespace-pre-wrap`]: wrap,
              }
            )}
          >
            {item?.title}
        </div>
        <div></div>
        <div
            className={clsx(
              `text-primary-alternative-light font-montserrat-semibold text-xl md:text[32px] col-span-2`
            )}
          >
            {item?.subtitle}
        </div>
      </div>
    </CardContent>
  </Card>
);
export default CardResult;
