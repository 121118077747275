import { FormAutocomplete } from 'components/FormikComponents';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

const Filters = ({ setMunicipio, municipioCatalog }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    setMunicipio(values.municipio);
  }, [values]);

  return (
    <FormAutocomplete
      className="w-full sm:w-1/2 md:w-1/3 font-montserrat-medium text-[13x]"
      label="Municipio"
      name="municipio"
      options={municipioCatalog}
      required
    />
  );
};

export default Filters;
