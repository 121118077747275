import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Question({ title, children = `HOLA amigos` }) {
  return (
    <div className="py-3">
      <Accordion sx={{ borderRadius: `10px !important` }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <h2 className="text-base font-montserrat-bold text-primary-darker md: py-3">{title}</h2>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: `0px 16px 16px !important` }}>
          <div className="text-[#7F7D8A] pl-4 text-sm font-montserrat leading-8 pr-6">
            {children}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
