import { Container } from '@mui/material';
import { ReactComponent as IcoTel } from 'assets/icons/ico_tel.svg';
const InformateSection = () => (
  <div className="bg-[#0595F0]">
    <Container>
      <div className=" w-full flex flex-row flex-wrap">
        <div className="w-full font-nutmeg">
          <div className="flex items-center flex-wrap justify-center py-10">
            <p className="text-[28px] md:text-[38px] text-white text-center lg:text-left">
              <b>¿Tienes alguna duda?</b>
            </p>
            <IcoTel className="m-5" />
            <p className="text-[28px] lg:text-[34px] text-white text-center lg:text-left ">
              <a href="tel:+523336682482"><b>33 3030 9350 ext. 8375 </b></a>
            </p>
          </div>
        </div>
      </div>
    </Container>
  </div>
);
export default InformateSection;
