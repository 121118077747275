import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './alerts.css';

const classes = {
  container: `swal-container`,
  popup: `swal-popup`,
  title: `swal-title`,
};

const success = (text, confirmCallback, cancelCallback, customProps) => {
  const successSwal = withReactContent(Swal);

  return successSwal
    .fire({
      allowOutsideClick: false,
      showCloseButton: false,
      customClass: { ...classes, confirmButton: `btn-confirm btn-success` },
      confirmButtonText: `Aceptar`,
      title: `¡Buen trabajo!`,
      html: text,
      icon: `success`,
      ...customProps,
    })
    .then((result) => {
      if (result.isConfirmed) {
        if (confirmCallback) {
          confirmCallback();
        }
      }
      if (result.isDenied || result.isDismissed) {
        if (cancelCallback) {
          cancelCallback();
        }
      }
      return result;
    });
};

const danger = (text, confirmCallback, cancelCallback, customProps) => {
  const dangerSwal = withReactContent(Swal);

  return dangerSwal
    .fire({
      allowOutsideClick: false,
      showCloseButton: false,
      customClass: { ...classes, confirmButton: `btn-confirm btn-danger` },
      confirmButtonText: `Aceptar`,
      title: `Oops...`,
      html: text,
      icon: `error`,
      ...customProps,
    })
    .then((result) => {
      if (result.isConfirmed) {
        if (confirmCallback) {
          confirmCallback();
        }
      }
      if (result.isDenied || result.isDismissed) {
        if (cancelCallback) {
          cancelCallback();
        }
      }
      return result;
    });
};

export { success, danger };
