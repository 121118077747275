import { Autocomplete } from 'components/FormComponents';
import { Field } from 'formik';

const FormComponent = ({ field, meta, form, ...props }) => (
  <Autocomplete
    {...field}
    form={form}
    error={!!(meta.touched && meta.error)}
    helperText={meta.touched && meta.error}
    {...props}
  />
);

export const FormAutocomplete = (props) => (
  <Field name={props.name}>
    {({ field, meta, form }) => <FormComponent field={field} meta={meta} form={form} {...props} />}
  </Field>
);
