import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

/**
 * Componente que provee a sus descendientes con métodos de Recaptcha v3.
 */
function RecaptchaProvider({ children }) {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}
      language="es"
    >
      {children}
    </GoogleReCaptchaProvider>
  );
}

export default RecaptchaProvider;
