/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// ** React Imports
import { Fragment, useEffect, useState } from 'react';
import './styles.scss';

// ** Third Party Components
import { Row, Col } from 'reactstrap';

// ** Calendar App Component Imports
import Calendar from './Calendar';
import DrawerEvent from './DrawerEvent';

// ** Blank Event Object
const CalendarComponent = ({ events }) => {
  const [openDrawer, setOpenDrawer] = useState(false),
    [selectedEvent, setSelectedEvent] = useState({});

  const [calendarEvent, setCalendarEvent] = useState(events);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
    setSelectedEvent(null);
  };

  useEffect(() => {
    const evCal = [...events];
    setCalendarEvent(
      evCal.map((obj) => {
        let auxStart = obj?.fecha?.split(`T`)[0];
        auxStart = obj?.hora ? `${auxStart}T${obj?.hora}:00.000Z` : obj.fecha;
        return {
          start: auxStart,
          extendedProps: {
            ...obj,
            municipio: obj?.municipio?.nombre,
            municipio_afijo: obj?.municipio?.afijo,
            colonia: obj?.colonia?.nombre,
          },
        };
      })
    );
  }, [events]);
  return (
    <Fragment>
      <div className="app-calendar overflow-hidden border-b">
        <Row>
          <Col className="position-relative">
            <Calendar
              events={calendarEvent}
              setSelectedEvent={setSelectedEvent}
              handleOpenDialog={toggleDrawer}
            />
          </Col>
        </Row>
      </div>
      <DrawerEvent
        toggleDrawer={toggleDrawer}
        openDrawer={openDrawer}
        selectedEvent={selectedEvent}
      />
    </Fragment>
  );
};

export default CalendarComponent;
