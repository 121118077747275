import { useEffect, useState } from 'react';
import { resolvePath } from 'utils/resolvePath';

const config = window.site_config;

export const useCatalog = (path, keys) => {
  const [catalog, setCatalog] = useState([]);

  const get = () => {
    if (!path) {
      setCatalog([]);
    } else {
      const catalog = resolvePath(config, path, []);
      setCatalog(
        catalog.map((item) => ({
          ...item,
          value: item[keys?.value ?? `value`],
          name: item[keys?.name ?? `name`],
        }))
      );
    }
  };

  useEffect(() => {
    get();
  }, [path]);

  return catalog;
};
