import { Container } from '@mui/material';
import ResilenciaHidrica from 'assets/images/resilencia_hidrica.png';

const QueEsSection = () => (
  <div id="que_es" className="scroll-mt-[64px]">
    <Container>
      <div className="w-full flex flex-row flex-wrap  py-[70px]">
        <div className="w-full md:w-1/3 flex justify-center items-center">
          <img
            className="object-contain w-[582px] h-[410px] pointer-events-none"
            src={ResilenciaHidrica}
            alt="Resilencia hidrica"
          />
        </div>
        <div className="w-full md:w-2/3 font-nutmeg pl-6">
          <h2 className="text-4xl md:text-5xl lg:text-[52px] text-primary-darker font-nutmeg-extrabold">
            ¿Qué es Nidos de Lluvia?
          </h2>
          <p className="text-xl lg:text-2xl text-primary-medium-dark !leading-[3rem] py-10">
            Es el programa del Gobierno de Jalisco que
            <b>
              {` busca implementar un nuevo modelo de abastecimiento, almacenamiento y uso del agua `}
            </b>
            en las colonias y localidades con mayor vulnerabilidad hídrica del Estado, apostando por una sana relación con el agua, sus ciclos y las disponibilidades naturales.
          </p>
        </div>
      </div>
    </Container>
  </div>
);

export default QueEsSection;
