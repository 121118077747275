import Layout from 'layouts/MainLayout';
import Home from 'views/Home';
import NotFound from 'views/NotFound';
import { CuidaTuNido } from 'views/CuidaTuNido';
import { solicitudConfig } from 'views/Solicitud/solicitudConfig';
import { informateConfig } from 'views/Informate/informateConfig';

const routesConfig = [solicitudConfig, ...informateConfig];

const routes = [
  {
    key: `LAYOUT`,
    element: <Layout />,
    path: `/`,
    children: [
      {
        key: `HOME`,
        element: <Home />,
        index: true,
      },
      ...routesConfig,
      {
        key: 'cuida_tu_nido',
        element: <CuidaTuNido />,
        path: 'cuida_tu_nido',
      }
    ],
  },
  {
    key: `NOT_FOUND`,
    element: <NotFound />,
    path: `*`,
  },
];

export default routes;
