import { TextField } from './TextField';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import clsx from 'clsx';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { CalendarPickerSkeleton } from '@mui/x-date-pickers/CalendarPickerSkeleton';
import parse from 'date-fns/parse';
import { useEffect } from 'react';

const regex = /^([0-2][0-9]|(3)[0-1])-(((0)[0-9])|((1)[0-2]))-\d{4}$/i;

export const DatePicker = ({
  className,
  name,
  label,
  value,
  onChange,
  onBlur,
  error,
  helperText,
  required,
  ...props
}) => {
  const onValueChange = (newValue) => {
    if (newValue === null) {
      return;
    }
    onChange({ target: { name, value: newValue } });
  };

  useEffect(() => {
    if (typeof value === `string` && value?.match(regex)) {
      onValueChange(parse(value, `dd-MM-yyyy`, new Date()));
    }
  }, [value]);

  return (
    <div className={clsx(`w-full`, className)}>
      <MuiDatePicker
        allowSameDateSelection
        PaperProps={{ sx: { borderRadius: `12px` } }}
        value={value}
        onChange={onValueChange}
        renderInput={({ inputRef, InputProps, inputProps }) => (
          <div className="flex align-center">
            <TextField
              ref={inputRef}
              {...inputProps}
              {...InputProps}
              label={label}
              error={error}
              helperText={helperText}
              placeholder="dd/mm/yyyy"
              required={required}
              onFocus={() => {
                if (onBlur) {
                  onBlur({ target: { name } });
                }
              }}
            />
          </div>
        )}
        renderLoading={() => <CalendarPickerSkeleton />}
        renderDay={(day, _value, DayComponentProps) => (
          <PickersDay
            {...DayComponentProps}
            sx={{
              backgroundColor: `primary.lightest`,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
            }}
          />
        )}
        {...props}
      />
    </div>
  );
};
