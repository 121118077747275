import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { ReactComponent as NLicon } from 'assets/icons/ico_ndll.svg';
import { ReactComponent as NLlogo } from 'assets/logos/logo_ndll_nuevo.svg';
import { Container, Divider } from '@mui/material';
import { AppbarLink } from './AppbarLink';
import { Drawer } from './Drawer';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { ReactComponent as IcoTinaco } from 'assets/icons/ico_tinaco_alt.svg';
import Pdf from '../../documents/NIDOS_DE_LLUVIA_AVISO_DE_PRIVACIDAD.pdf';
import PdfROP from '../../documents/ROP_NDLL24.pdf';
import PdfActualizacionROP from '../../documents/actualizacion_rop_NDLL.pdf';

const pages = [
  { label: `Inicio`, to: `/`, onClick: () => window.scrollTo({ top: 0, behavior: `smooth` }) },
  { label: `¿Qué es?`, to: `/#que_es`, hash: true },
  { label: `Convocatoria`, to: `/#convocatoria`, hash: true },
  {
    label: `Infórmate`,
    to: `/FAQ`,
    subroutes: [
      { label: `Calendario de juntas`, to: `/calendario_juntas` },
      { label: `Preguntas frecuentes`, to: `/FAQ` },
      {
        label: `Aviso de privacidad`,
        to: Pdf,
        external: true,
      },
      { 
        label: `Reglas de operación`,
        to: PdfROP,
        external: true
      },
      /*{
        label: 'Actualización de reglas de operación',
        to: PdfActualizacionROP,
        external: true
      }*/
    ],
  },
  { label: `Cuida tu Nido de Lluvia`, to: `/cuida_tu_nido`, hash: true },
];

const ResponsiveAppBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <AppBar
      position="sticky"
      sx={{ backgroundColor: `primary.dark`, height: 64, justifyContent: `center`, boxShadow: 0 }}
    >
      <Drawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} pages={pages} />
      <Container maxWidth="xl" padding={0}>
        <Toolbar disableGutters className="flex justify-between">
          <div className="flex md:hidden">
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <div className="flex mx-2">
            <Link
              component={RouterLink}
              to="/"
              underline="none"
              onClick={() => window.scrollTo({ top: 0, behavior: `smooth` })}
            >
              <NLlogo className="hidden md:flex h-[40px]" />
            </Link>
            <Link component={RouterLink} to="/" underline="none">
              <NLicon
                className="flex md:hidden h-[40px]"
                onClick={() => window.scrollTo({ top: 0, behavior: `smooth` })}
              />
            </Link>
          </div>
          <div className="flex-1 justify-end items-center hidden md:flex">
            {pages.map((page) => (
              <AppbarLink
                key={page.label}
                label={page.label}
                to={page.to}
                subroutes={page.subroutes}
                hash={page.hash}
                onClick={page.onClick}
                sx={{ whiteSpace: `nowrap` }}
              />
            ))}
          </div>
          <div className="hidden md:flex grow-0 items-center content-center">
            <Divider
              className="!mx-3 !border-white !h-6 !self-center"
              orientation="vertical"
              variant="middle"
              flexItem
            />
            <AppbarLink
              Icon={IcoTinaco}
              label="Solicita un nido de lluvia"
              to="/solicitud_apoyo"
              sx={{ ml: { md: `30px`, lg: `45px` } }}
            />
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
