export default [
    {
      region: 'AMG',
      colorAccent: '#00ABD7',
      municipios: [
        {
          municipio: `Zapopan`,
          colonias: [
            '12 de Diciembre',
            //'Agua Fría',
            'Benito Juárez Norte',
            'Brisas de la Primavera',
            'El Rehilete',
            'El Tizate',
            'Indígena de Mezquitán',
            'La Noria',
            'Lomas (Vistas) del Centinela',
            //'Lomas de la Primavera',
            'Lomas del Refugio',
            'Los Cerritos',
            'Mesa Colorada Poniente',
            'Mesa de los Ocotes',
            'Nueva España',
            'Valle de la Primavera',
            //'Villas de Guadalupe',
           // 'Vistas del Centinela',
          ],
        },
        {
          municipio: `Guadalajara`,
          colonias: [
            '1ro de Mayo',
            '5 de Mayo',
            'Aarón Joaquín',
            //'Belisario Domínguez',
            //'Circunvalación Oblatos',
            'El Bethel',
            //'Fidel Velázquez',
            'Fraccionamiento Arandas',
            //'Heliodoro Hernández Loza',
            'Hermosa Provincia',
            //'Higuerillas',
            //'Huentitán El Alto',
            'Insurgentes 1ra Sección',
            'Insurgentes 2da Sección',
            'Insurgentes 3ra Sección',
            //'Jardines de Guadalupe',
            //'La Guadalupana',
            'Lagos de Oriente',
            'Lomas de Polanco',
            'Lomas del Paraíso I Sección',
            'Oblatos 1ra Sección',
            'Oblatos 2da Sección',
            //'Rancho Nuevo',
            //'San Crispín',
            //'San Isidro',
            'San José Río Verde 1ra Sección',
            //'San Marcos',
            //'San Miguel de Huentitán',
            'San Ramón',
            //'San Vicente',
            //'Santa Mónica',
            //'Santa Rosa',
            //'Talpita',
            'Tetlán II',
            'Tetlán'
          ],
        },
        {
          municipio: `Tlaquepaque`,
          colonias: [
            'El Zalate',
            'Francisco I Madero 2da Sección',
            'La Cofradía',
            'La Gigantera',
            'La Loma',
            'Nueva Santa María',
            'Potrero del Sauz',
            'San Pedrito',
            'Tateposco',
          ],
        },
        {
          municipio: `Tlajomulco`,
          colonias: [
            'Arcos de San Sebastián',
            'Balcones de Santa Anita',
            'Cima del Sol',
            'El Manantial',
            'Jardines de San Sebastián',
            'La Fortuna',
            'La Gigantera',
            'La Nueva Victoria',
            'La Victoria',
            'Lomas de San Agustín',
            'Paseos del Valle',
            'Puerta Real',
            'Rancho Alegre'
          ],
        },
        {
          municipio: `Tonalá`,
          colonias: [
            'Arroyo de en Medio',
            'El Vado',
            'Jauja',
            'Los Pajaritos',
            'San Miguel La Punta',
            'Santa Isabel',
            'Santa Rosa',
          ],
        },
        {
          municipio: `El Salto`,
          colonias: [
            'El Verde',
            'San José del Castillo (El Castillo)',
            'San José del Quince',
            'Villas de Guadalupe',
          ],
        },
        {
          municipio: `Zapotlanejo`,
          colonias: [
            'Agua Blanca',
            'Balcones de Huisquilco',
            'Constitución',
            'El Santuario',
            'Jardines del Paraíso',
            'La Ceja',
            'La Cruz',
            'San Francisco',
            'San Miguelito',
            'Tres Flores',
          ],
        },
        {
          municipio: `Juanacatlán`,
          colonias: [
            'Cabecera Municipal',
            'La Playa',
            'Tateposco',
          ],
        },
        {
          municipio: `Ixtlahuacán de los Membrillos`,
          colonias: [
            'Arena',
            'Bajío o Barrio Pobre',
            'Ojo de Agua',
          ],
        },
      ]
    },
    {
      region: 'Interior',
      colorAccent: '#0D2C43',
      municipios: [
        {
          municipio: `Tototlán`,
          colonias: [
            'Basulto',
            'Cabecera Municipal (Centro)',
            'Cuesta Chica',
            'El Ranchito',
            'La Huaracha',
            'Las Eras',
            'Palo Dulce',
            'Ramblas Chico',
            'San Isidro',
            'Tajo (Cabecera Municipal)',
          ]        
        },
        {
          municipio: `Poncitlán`,
          colonias: [
            'La Cuesta de Mezcala',
            'Mezcala',
          ]        
        },
        {
          municipio: `Ocotlán`,
          colonias: [
            '6 de Noviembre',
            'El Nuevo Fuerte',
            'Lázaro Cárdenas',
            'San Juan',
          ]        
        },
        {
          municipio: `La Barca`,
          colonias: [
            'El Abrevadero',
            'El Limón',
            'La Paz de Ordaz',
            'Los Ángeles',
            'San Pedro de Ruiz',
            'Santa Cecilia',
          ]        
        },
        {
          municipio: `Jocotepec`,
          colonias: [
            'El Chante (Chantepec)',
            'Potrerillos',
            'San Cristóbal',
            'San Juan Cosalá',
          ]        
        },
      ]
    }
];