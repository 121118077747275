import printValue from 'yup/es/util/printValue';

const yupLocaleEsp = {
  mixed: {
    default: `Valor inválido`,
    required: `El campo es requerido.`,
    oneOf: `\${path} must be one of the following values: \${values}`,
    notOneOf: `\${path} must not be one of the following values: \${values}`,
    defined: `\${path} must be defined`,
    notType: ({ path, type, value, originalValue }) => {
      if (type === `date`) {
        return `Este campo debe ser una fecha válida.`;
      }
      if (type === `number`) {
        return `Este campo debe ser un número válido.`;
      }

      const isCast = originalValue != null && originalValue !== value;
      let msg = `${`${path} debe ser un \`${type}\`, pero el valor final fue: \`${printValue(
        value,
        true
      )}\``}${isCast ? ` (cast from the value \`${printValue(originalValue, true)}\`).` : `.`}`;

      if (value === null || originalValue === null) {
        msg += `\n Si se pretende que "null" sea un valor vacío, asegúrese de marcar el esquema como \`.nullable()\``;
      }

      return msg;
    },
  },
  string: {
    length: `El campo debe tener exactamente \${length} caracteres`,
    min: `El campo debe tener al menos \${min} caracteres. `,
    max: `El campo no puede ser mayor que \${max} caracteres.`,
    matches: `\${path} must match the following: "\${regex}"`,
    email: `El campo debe ser una dirección de correo electrónico válida.`,
    url: `Debe de ser una url valida (https://www.google.com)`,
    uuid: `\${path} must be a valid UUID`,
    trim: `\${path} must be a trimmed string`,
    lowercase: `\${path} must be a lowercase string`,
    uppercase: `\${path} must be a upper case string`,
  },
  number: {
    min: `El campo debe ser al menos \${min}`,
    max: `El campo no puede ser mayor que \${max}`,
    lessThan: `\${path} must be less than \${less}`,
    moreThan: `\${path} must be greater than \${more}`,
    positive: `\${path} must be a positive number`,
    negative: `\${path} must be a negative number`,
    integer: `El campo tiene que ser un número.`,
  },
  date: {
    min: `\${path} field must be later than \${min}`,
    max: `\${path} field must be at earlier than \${max}`,
  },
  boolean: {
    isValue: `\${path} field must be \${value}`,
  },
  object: {
    noUnknown: `\${path} field has unspecified keys: \${unknown}`,
  },
  array: {
    min: `\${path} field must have at least \${min} items`,
    max: `\${path} field must have less than or equal to \${max} items`,
    length: `\${path} must be have \${length} items`,
  },
};

export default yupLocaleEsp;
