import { Button as MuiButton } from '@mui/material';
import clsx from 'clsx';

export const ContainedButton = ({ className, sx, children, textColor, ...props }) => (
  <div className={clsx(`w-64`, className)}>
    <MuiButton
      variant="contained"
      fullWidth
      sx={{
        borderRadius: `12px`,
        textTransform: `none`,
        color: textColor ?? `white`,
        fontFamily: `Nutmeg Regular`,
        ...sx,
      }}
      {...props}
    >
      {children}
    </MuiButton>
  </div>
);
