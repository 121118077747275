import { Container, Link } from '@mui/material';
import clsx from 'clsx';
import { ContainedButton } from 'components/Buttons';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as IcoTinaco } from 'assets/icons/ico_btn_tinaco.svg';
import pdf from 'documents/NIDOS_DE_LLUVIA_CONVOCATORIA_2024.pdf';

const ListSteps = ({ children, index, className }) => (
  <div
    className={clsx(
      `flex flex-row items-center md:px-6 flex-wrap text-center justify-center md:flex-nowrap md:justify-start md:text-left`,
      className
    )}
  >
    <div className="flex justify-start items-center py-2">
      <span className="w-[44px] h-[44px]  bg-[#0595F0] rounded-full m-6">
        <p className="text-[28px] text-white font-montserrat-bold flex justify-center items-center">
          {index}
        </p>
      </span>
    </div>
    {children}
  </div>
);

const SolicitarApoyoSection = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-[#EDF4FF]">
      <Container maxWidth={`lg`}>
        <div className=" w-full flex flex-col flex-wrap py-[70px] justify-center">
          <div className="w-full font-nutmeg-extrabold">
            <div className="text-4xl md:text-5xl lg:text-[52px] text-primary-darker text-center">
              <b>¿Cómo puedo solicitar el apoyo?</b>
            </div>
            <p className="text-xl lg:text-2xl text-primary-darker font-montserrat-medium px-12 py-[46px]">
            Para ser beneficiadas con el programa, sigue estos pasos:
            </p>
            <ListSteps index={1}>
              <p className="text-xl lg:text-2xl text-primary-darker font-montserrat-medium px-4">
                Revisa que cumplas con los <b>requisitos publicados en la convocatoria.</b>
              </p>
            </ListSteps>
            <ListSteps index={2}>
              <p className="text-xl lg:text-2xl text-primary-darker font-montserrat-medium px-4">
                <b>Asiste a la junta comunitaria de tu colonia</b> con tu identificación oficial y comprobante de domicilio. Ahí podrás iniciar el proceso para solicitar tu Nido de Lluvia.
              </p>
            </ListSteps>

            <div className="w-full flex flex-row flex-auto items-center justify-center md:justify-between gap-6 flex-wrap md:flex-nowrap mr-6 m-0 px-6 md:mx-6">
              <div className="flex flex-row  items-center w-full md:w-1/2 max-w-[488px] flex-wrap md:flex-nowrap justify-center md:justify-start lg:whitespace-nowrap">
                <span className="min-w-[44px] min-h-[44px]  bg-[#0595F0] rounded-full ml-6 md:ml-0 m-6">
                  <p className="text-[28px] text-white font-montserrat-bold flex justify-center items-center">
                    3
                  </p>
                </span>
                <p className="text-xl lg:text-2xl text-primary-darker font-montserrat-medium px-4 w-full text-center md:text-left md:w-auto">
                  <b>Inicia tu pre-registro aquí:</b>
                </p>
              </div>
              <ContainedButton
                color="secondary"
                textColor="#021E43"
                onClick={() => navigate(`/solicitud_apoyo`)}
                className="w-full md:w-1/2  py-6 max-w-[494px]"
                sx={{
                  paddingY: `12px`,
                  fontWeight: `bold`,
                  fontSize: { md: `16px`, lg: `18px` },
                  display: `flex`,
                }}
              >
                <IcoTinaco className="min-w-[32px] min-h-[32px] mr-5" />
                Solicita un nido de lluvia
              </ContainedButton>
            </div>
            <div className="w-full flex flex-row flex-auto items-center justify-center md:justify-between gap-6 flex-wrap md:flex-nowrap px-6 m-0 md:mx-6">
              <ContainedButton
                color="alternativeButton"
                textColor="#0066A7"
                className="w-full md:w-1/2 py-6 max-w-[488px]"
                sx={{
                  paddingY: `12px`,
                  fontSize: { md: `16px`, lg: `18px` },
                  fontWeight: `bold`,
                  fontFamily: `Montserrat SemiBold`,
                  textAlign: `center`,
                }}
                component={Link}
                href={pdf}
                target="_blank"
                rel="noopener noreferrer"
              >
                Descarga la convocatoria
              </ContainedButton>
              <ContainedButton
                color="alternativeButton"
                onClick={() => navigate(`/calendario_juntas`)}
                textColor="#0066A7"
                className="w-full md:w-1/2 max-w-[494px]"
                sx={{
                  paddingY: `12px`,
                  fontSize: { md: `16px`, lg: `18px` },
                  fontWeight: `bold`,
                  fontFamily: `Montserrat SemiBold`,
                }}
              >
                Calendario de juntas comunitarias
              </ContainedButton>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default SolicitarApoyoSection;
