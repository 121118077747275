import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import routes from 'constants/routes';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'constants/muiTheme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import esLocale from 'date-fns/locale/es';

function App() {
  const renderRoutes = (route) => {
    const element = route.routes ? (
      <Routes>{route.routes.map(renderRoutes)}</Routes>
    ) : (
      route.element
    );

    return (
      <Route key={route.key} element={element} path={route.path} exact {...route}>
        {Array.isArray(route.children) ? route.children.map(renderRoutes) : null}
      </Route>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>{routes.map(renderRoutes)}</Routes>
        </BrowserRouter>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
