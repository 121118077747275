import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { IconButton, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import Mapa from 'components/Map';

const style = {
  width: `100%`,
};

const TitleDrawer = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <Box sx={{ m: 0, backgroundColor: `#F8FBFF` }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: `absolute`,
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Box>
  );
};

export default function DrawerEvent({ selectedEvent, toggleDrawer, openDrawer }) {
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down(`sm`));

  const domicilio = `
  ${selectedEvent?.calle ? `${selectedEvent?.calle} ` : ``}
  ${selectedEvent?.num_exterior ? `#${selectedEvent?.num_exterior}, ` : ``}
  ${selectedEvent?.colonia ? `Col. ${selectedEvent?.colonia}, ` : ``}${
    selectedEvent?.codigo_postal ? `C.P. ${selectedEvent?.codigo_postal}, ` : ``
  }${selectedEvent?.municipio ? `${selectedEvent?.municipio}.` : ``}`;

  const date = new Date(selectedEvent?.fecha ?? null);
  let year = date.getUTCFullYear();
  let month = date.getUTCMonth() + 1;
  let dt = date.getUTCDate();
  let hours = selectedEvent?.hora ?? date.getUTCHours();
  let minutes = date.getUTCMinutes();

  let meridiemDate = date.getUTCHours() < 12 ? `AM` : `PM`;
  if (selectedEvent?.hora) {
    meridiemDate = selectedEvent < 12 ? `AM` : `PM`;
  }
  if (dt < 10) {
    dt = `0` + dt;
  }
  if (month < 10) {
    month = `0` + month;
  }
  if (minutes < 10) {
    minutes = `0` + minutes;
  }
  if (hours < 10) {
    hours = `0` + hours;
  }

  let time = selectedEvent?.hora ? `${selectedEvent?.hora}` : `${hours}:${minutes}`;
  const formatDate = `${dt}/${month}/${year}`; //prints expected format.

  const list = () => (
    <div className="flex flex-1 w-full p-4 flex-col md:max-w-[387px]">
      <div className="py-2">
        <p className="font-montserrat-medium text-sm text-primary-dark">Fecha de la junta</p>
        <p className="text-primary-alternative-light font-montserrat-semibold text-[16px]">
          {formatDate}
        </p>
      </div>
      <div className="py-2">
        <p className="font-montserrat-medium text-sm text-primary-dark">Hora de la junta</p>
        <p className="text-primary-alternative-light font-montserrat-semibold text-[16px]">
          {time}
        </p>
      </div>
      <div className="py-2">
        <p className="font-montserrat-medium text-sm text-primary-dark">Colonia</p>
        <p className="text-primary-alternative-light font-montserrat-semibold text-[16px]">
          {selectedEvent?.colonia}
        </p>
      </div>
      <div className="py-2">
        <p className="font-montserrat-medium text-sm text-primary-dark">Municipio</p>
        <p className="text-primary-alternative-light font-montserrat-semibold text-[16px]">
          {selectedEvent?.municipio}
        </p>
      </div>
      <div className="py-2">
        <p className="font-montserrat-medium text-sm text-primary-dark">
          Nombre o referencia de la junta
        </p>
        <p className="text-primary-alternative-light font-montserrat-semibold text-[16px]">
          {selectedEvent?.nombre_lugar}
        </p>
      </div>
      <div className="py-2">
        <p className="font-montserrat-medium text-sm text-primary-dark">Dirección</p>
        <p className="text-primary-alternative-light font-montserrat-semibold text-[16px]">
          {domicilio}
        </p>
      </div>
    </div>
  );

  return (
    <div>
      <React.Fragment>
        <Drawer
          variant="temporary"
          ModalProps={{
            keepMounted: false,
          }}
          anchor={`right`}
          open={openDrawer}
          onClose={toggleDrawer}
          key={`right`}
          PaperProps={
            fullScreen
              ? {
                  style,
                }
              : {}
          }
        >
          <TitleDrawer onClose={toggleDrawer}>
            <h1 className="text-[18px] font-montserrat-bold text-primary-darker p-4">
              Detalle Junta Comunitaria
            </h1>
          </TitleDrawer>
          {list()}
          <Mapa latitud={selectedEvent?.latitud} longitud={selectedEvent?.longitud} />
        </Drawer>
      </React.Fragment>
    </div>
  );
}
