import Futurologo from 'assets/logos/logo_agua_para_futuro.png';
import CDTlogo from 'assets/logos/logo_cdt.png';
import JALlogo from 'assets/logos/logo_jal.png';
import SIAPAlogo from 'assets/logos/logo_cea.svg';
import GIAlogo from 'assets/logos/logo_gestion_territorio.png';
import { Container } from '@mui/material';

const LogoWrapper = ({ children }) => (
  <div className="flex justify-center w-full my-3 xs:w-1/2 lg:my-0 lg:w-1/5">{children}</div>
);

export default function Footer() {
  return (
    <div className="w-full flex items-center min-h-[189px] bg-primary-dark">
      <Container padding={0}>
        <div className="flex justify-center items-center  flex-wrap">
          <LogoWrapper>
            <img className="object-contain w-[172px] h-[35px]" src={SIAPAlogo} alt="CEA logo" />
          </LogoWrapper>
          <LogoWrapper>
            <img className="object-contain w-[172px] h-[37px]" src={GIAlogo} alt="GIA logo" />
          </LogoWrapper>
          <LogoWrapper>
            <img className="object-contain w-[177px] h-[57px]" src={CDTlogo} alt="CDT logo" />
          </LogoWrapper>
          <LogoWrapper>
            <img className="object-contain w-[120px] h-[120px]" src={JALlogo} alt="JAL logo" />
          </LogoWrapper>
        </div>
      </Container>
    </div>
  );
}
