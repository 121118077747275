import { Container } from '@mui/material';
import CalendarComponent from 'components/Calendar';
import { Formik } from 'formik';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import Filters from './Filters';
import { getByMunicipioId } from 'services/juntaComunitariaService';
import { danger } from 'utils/Alerts';
import { useCatalog } from 'hooks/useCatalog';

const CalendarioJuntas = () => {
  const municipioCatalog = useCatalog(`municipios`, { value: `id`, name: `nombre` });
  const [municipio, setMunicipio] = useState(null);
  const [events, setEvents] = useState([{}]);

  useEffect(() => {
    if (!municipio) {
      return;
    }
    const get = async () => {
      await getByMunicipioId(municipio)
        .then((response) => {
          const { data } = response.data;
          setEvents(data);
        })
        .catch((e) => danger(e.message ?? e));
    };
    get();
  }, [municipio]);

  return (
    <div className="flex flex-1 w-full sm:p-6">
      <Container
        className="flex flex-1 rounded-lg bg-white py-6 md:!px-6"
        maxWidth="xl"
        sx={{ boxShadow: `0px 0px 22px #D3D5E380;` }}
      >
        <motion.div
          className="flex flex-row justify-between max-w-full"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <h1 className="text-lg font-montserrat-bold text-primary-darker py-2">
            Calendario de juntas comunitarias
          </h1>
        </motion.div>
        <p className="font-montserrat-medium text-[16px]">
          Selecciona el municipio en donde vives para ver las juntas comunitarias programadas en el
          mes.
        </p>

        <Formik
          validateOnMount
          validateOnChange
          initialValues={{ municipio: municipioCatalog[0]?.id }}
          enableReinitialize
        >
          {(props) => (
            <Filters {...props} setMunicipio={setMunicipio} municipioCatalog={municipioCatalog} />
          )}
        </Formik>
        <CalendarComponent events={events} />
      </Container>
    </div>
  );
};

export default CalendarioJuntas;
