import * as React from 'react';
import Menu from '@mui/material/Menu';
import { ButtonBase } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import { PopupLink } from './PopupLink';

const labelStyle = {
  my: `1rem`,
  mr: { md: `30px`, lg: `45px` },
  fontFamily: `Montserrat Bold`,
  fontSize: 12,
  transition: `all 0.3s ease`,
  color: `white`,
  '&:hover,&:focus': {
    color: `primary.light`,
  },
};

export default function PopupMenu({ label, subroutes = [] }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  let location = useLocation();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const focused = React.useMemo(
    () => subroutes.some(({ to }) => !!matchPath(location.pathname, to)),
    [location.pathname]
  );

  return (
    <div>
      <ButtonBase
        sx={{ ...labelStyle, color: focused ? `primary.light` : `white` }}
        aria-controls={open ? `basic-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? `true` : undefined}
        onClick={handleClick}
      >
        {label}
      </ButtonBase>
      <Menu
        PopoverClasses={{ paper: `!bg-primary-dark` }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `basic-button`,
        }}
      >
        {subroutes.map((route) => (
          <PopupLink
            key={route.label}
            label={route.label}
            to={route.to}
            external={route.external}
          />
        ))}
      </Menu>
    </div>
  );
}
