import { Container } from '@mui/material';
import NDlogo from 'assets/logos/logo_ndll_nuevo.png';
import { ReactComponent as WaveSVG } from 'assets/images/img_ondas_banner.svg';
import { motion } from 'framer-motion';
import { ContainedButton } from 'components/Buttons';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as IcoTinaco } from 'assets/icons/ico_btn_tinaco.svg';

const Waves = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-gradient-to-b from-primary-dark to-[#18426a] h500:min-h-[calc(100vh-200px)]  flex flex-col">
      <Container>
        <div className="w-full flex flex-col flex-wrap justify-center pt-[30px] md:pt-[75px] lg:pt-[150px]">
          <motion.div
            className="w-full flex justify-center"
            initial={{ opacity: 0, scale: 0.6 }}
            animate={{ opacity: 1, scale: 1, transition: { delay: 0.1 } }}
          >
            <img
              className="object-contain w-[508px] h-[50px]"
              src={NDlogo}
              alt="Logo Nido de Lluvia"
            />
          </motion.div>
          <motion.div
            className="font-nutmeg-black flex justify-center mt-10 w-full"
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
          >
            <div className="inline-block">
              <div className="flex w-full">
                <ContainedButton
                  color="secondary"
                  textColor="#021E43"
                  onClick={() => navigate(`/solicitud_apoyo`)}
                  className="w-full py-6 max-w-[490px]"
                  sx={{
                    fontWeight: `bold`,
                    fontSize: { md: `24px`, sm: `20px` },
                    display: `flex`,
                  }}
                >
                  <IcoTinaco className="min-w-[32px] min-h-[32px] mr-5" />
                  Solicita un nido de lluvia
                </ContainedButton>
              </div>
            </div>
          </motion.div>
        </div>
      </Container>
      <div
        className="w-full flex flex-row overflow-hidden flex-1"
        style={{ background: `linear-gradient(180deg, transparent 50%, #FFFFFF 50%)` }}
      >
        <div>
          <WaveSVG />
        </div>
        <div style={{ transform: `scaleX(-1) translateX(2px)` }}>
          <WaveSVG />
        </div>
      </div>
    </div>
  );
};

export default Waves;
