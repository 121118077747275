import { Link as RouterLink, useMatch, useResolvedPath } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import { Typography, Link } from '@mui/material';
import List from '@mui/material/List';
import { HashLink } from 'react-router-hash-link';

const labelStyle = {
  my: `0.5rem`,
  fontFamily: `Montserrat Bold`,
  color: `primary.dark`,
};

export function DrawerLink({ label, to, subroutes = [], hash, handleDrawerToggle, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: `${resolved.pathname}/*` });

  if (hash) {
    return (
      <ListItemButton
        role={undefined}
        component={HashLink}
        to={`${resolved?.pathname}${resolved.hash}`}
        onClick={handleDrawerToggle}
        {...props}
      >
        <Typography sx={labelStyle}>{label}</Typography>
      </ListItemButton>
    );
  }
  return (
    <>
      <ListItemButton
        role={undefined}
        component={RouterLink}
        to={to}
        selected={!!match && !subroutes.length}
        onClick={handleDrawerToggle}
        sx={subroutes.length ? { pointerEvents: `none`, cursor: `default` } : {}}
        tabIndex={subroutes.length && -1}
        {...props}
      >
        <Typography sx={labelStyle}>{label}</Typography>
      </ListItemButton>
      {subroutes && (
        <List component="div" disablePadding>
          {subroutes.map((route) => {
            const buttonProps = {
              role: undefined,
              selected: !!match && !subroutes.length,
              onClick: handleDrawerToggle,
            };
            if (route.external) {
              return (
                <ListItemButton
                  {...buttonProps}
                  key={route.label}
                  href={route.to}
                  component={Link}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ pl: 4 }}
                >
                  <Typography sx={labelStyle}>{route.label}</Typography>
                </ListItemButton>
              );
            }

            return (
              <ListItemButton
                {...buttonProps}
                key={route.label}
                component={RouterLink}
                to={route.to}
                sx={{ pl: 4 }}
              >
                <Typography sx={labelStyle}>{route.label}</Typography>
              </ListItemButton>
            );
          })}
        </List>
      )}
    </>
  );
}
