import CalendarioJuntas from './CalendarioJuntas';
import { FAQ } from './FAQ';

export const informateConfig = [
  {
    key: `FAQ`,
    path: `FAQ`,
    element: <FAQ />,
  },
  {
    key: `CALENDARIO`,
    path: `calendario_juntas`,
    element: <CalendarioJuntas />,
  },
];
