import { FormSection } from 'components/FormStepper/FormSection';
import { Divider } from '@mui/material';
import { FormInput, FormAutocomplete, FormDatePicker } from 'components/FormikComponents';
import { useCatalog } from 'hooks/useCatalog';

const catalogoSexo = [
  { name: `Mujer`, value: `M` },
  { name: `Hombre`, value: `H` },
  { name: `Prefiero no contestar`, value: `NA` },
];

export const DatosSolicitante = () => {
  const nacionalidadCatalog = useCatalog(`nacionalidad`);
  const entidadCatalog = useCatalog(`entidades`, { value: `id`, name: `nombre` });

  return (
    <form>
      <FormSection>
        <FormInput className="w-full px-3 sm:w-1/2 md:w-1/3" label="CURP" name="curp" disabled />
        <div className="hidden sm:flex sm:w-1/2 md:w-1/3" />
        <div className="hidden md:flex sm:w-1/2 md:w-1/3" />
        <FormInput
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Nombre(s)"
          name="nombres"
          disabled
        />
        <FormInput
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Primer Apellido"
          name="apellido_paterno"
          disabled
        />
        <FormInput
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Segundo Apellido"
          name="apellido_materno"
          disabled
        />
        <FormDatePicker
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Fecha de nacimiento"
          name="nacimiento"
          disabled
        />
        <FormAutocomplete
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Entidad de nacimiento"
          name="entidad"
          options={entidadCatalog}
          disabled
        />
        <FormAutocomplete
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Sexo registrado al nacimiento"
          name="genero"
          options={catalogoSexo}
          disabled
        />
        <FormInput className="w-full px-3 sm:w-1/2 md:w-1/3" label="Edad" name="edad" disabled />
        <FormAutocomplete
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Nacionalidad de nacimiento"
          name="nacionalidad"
          options={nacionalidadCatalog.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          )}
          disabled
        />
        <div className="w-full mt-8">
          <Divider light />
        </div>
      </FormSection>
    </form>
  );
};
