import { Checkbox } from 'components/FormComponents';
import { Field, FastField } from 'formik';

const FormComponent = ({ field, ...props }) => <Checkbox {...field} {...props} />;

export const FormCheckbox = ({ fast = true, ...props }) => {
  if (fast) {
    return (
      <FastField name={props.name}>
        {({ field, meta }) => <FormComponent field={field} meta={meta} {...props} />}
      </FastField>
    );
  }

  return (
    <Field name={props.name}>
      {({ field, meta }) => <FormComponent field={field} meta={meta} {...props} />}
    </Field>
  );
};
