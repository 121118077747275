import { Link as RouterLink, useMatch, useResolvedPath } from 'react-router-dom';
import Link from '@mui/material/Link';
import PopupMenu from './PopupMenu';
import { HashLink } from 'react-router-hash-link';

const labelStyle = {
  my: `1rem`,
  mr: { md: `30px`, lg: `45px` },
  color: `white`,
  fontFamily: `Montserrat Bold`,
  fontSize: 12,
  transition: `all 0.3s ease`,
  '&:hover': {
    color: `primary.light`,
  },
};

export function AppbarLink({ label, to, subroutes, hash, Icon, sx, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: `${resolved.pathname}/*` });

  if (subroutes) {
    return <PopupMenu label={label} subroutes={subroutes} />;
  }

  if (hash) {
    return (
      <Link
        sx={{
          ...labelStyle,
          ...sx,
        }}
        component={HashLink}
        to={`${resolved?.pathname}${resolved.hash}`}
        underline="none"
        smooth
        {...props}
      >
        {label}
      </Link>
    );
  }

  return (
    <Link
      sx={{
        ...labelStyle,
        ...sx,
        color: match ? `primary.light` : `white`,
      }}
      component={RouterLink}
      to={to}
      underline="none"
      {...props}
    >
      <div className="flex flex-row items-center">
        {Icon && <Icon className="w-[22px] h-[22px] mr-3" />}
        {label}
      </div>
    </Link>
  );
}
