import { Children } from 'react';
import clsx from 'clsx';

export const FormSection = ({ className, Icon, title, children }) => (
  <div className={clsx(`flex flex-1 flex-col my-4`, className)}>
    {title && (
      <div className="flex flex-row justify-start items-center gap-4 pt-2 pb-3">
        {Icon && <Icon sx={{ color: `primary.light`, fontSize: 28 }} />}
        <h2 className="text-base text-primary-light font-montserrat-semibold">{title}</h2>
      </div>
    )}
    <div className="flex flex-1 flex-wrap justify-start">
      {Children.map(children, (child) => child !== null && child)}
    </div>
  </div>
);
