import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import { Box, FormHelperText } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import clsx from 'clsx';
import { forwardRef } from 'react';

// Estilo base del campo de texto
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: `relative`,
    backgroundColor: theme.palette.mode === `light` ? `#fcfcfb` : `#2b2b2b`,
    border: `1.5px solid #D8D6DE`,
    fontSize: 14,
    width: `100%`,
    padding: `6px 12px`,
    transition: theme.transitions.create([`border-color`, `background-color`, `box-shadow`]),
    fontFamily: `Montserrat Regular`,
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.light, 0.15)} 0 0 0 0.1rem`,
      borderColor: theme.palette.primary.light,
    },
  },
}));

const errorInput = {
  '& .MuiInputBase-input': {
    borderColor: `red`,
    '&:focus': {
      boxShadow: `${alpha(`#ff0000`, 0.15)} 0 0 0 0.1rem`,
      borderColor: `red`,
    },
  },
};

// Estilo cuando el autocomplete está habilitado como múltiple
const MultipleContainer = styled(`div`)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  borderRadius: 4,
  position: `relative`,
  backgroundColor: theme.palette.mode === `light` ? `#fcfcfb` : `#2b2b2b`,
  border: `1.5px solid #D8D6DE`,
  fontSize: 14,
  padding: `2px 12px`,
  transition: theme.transitions.create([`border-color`, `background-color`, `box-shadow`]),
  fontFamily: `Montserrat Regular`,
  '&:focus-within': {
    boxShadow: `${alpha(theme.palette.primary.light, 0.15)} 0 0 0 0.1rem`,
    borderColor: theme.palette.primary.light,
  },
}));

const errorMultiple = {
  borderColor: `red`,
  '&:focus-within': {
    boxShadow: `${alpha(`#ff0000`, 0.15)} 0 0 0 0.1rem`,
    borderColor: `red`,
  },
};

export const TextField = forwardRef(
  (
    {
      className,
      label,
      error,
      required,
      helperText,
      InputLabelProps,
      multiple,
      autocompleteStyle,
      ...props
    },
    ref
  ) => (
    <div className={clsx(`w-full my-2`, className, { [`!pr-0`]: autocompleteStyle })}>
      <FormControl variant="standard" fullWidth>
        <InputLabel
          shrink
          sx={{
            fontFamily: `Montserrat Medium`,
            fontSize: 16,
            color: `#445065`,
            display: `flex`,
            gap: `3px`,
          }}
          {...InputLabelProps}
        >
          {label}
          {required && (
            <Box element="p" sx={{ color: `primary.light` }}>
              *
            </Box>
          )}
        </InputLabel>
        {!multiple ? (
          <BootstrapInput ref={ref} sx={error && { ...errorInput }} {...props} />
        ) : (
          <MultipleContainer sx={error && { ...errorMultiple }}>
            <InputBase
              sx={{ display: `flex`, flexWrap: `wrap`, paddingRight: 0 }}
              fullWidth
              ref={ref}
              {...props}
            />
          </MultipleContainer>
        )}
        <FormHelperText error={error} sx={{ fontSize: 11.5 }}>
          {helperText}
        </FormHelperText>
      </FormControl>
    </div>
  )
);
