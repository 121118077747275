import { useState } from 'react';
import { FormStepper } from 'components/FormStepper/FormStepper';
import { DatosSolicitante } from './formulario/DatosSolicitante';
import { Preregistro } from './formulario/Preregistro';
import { BusquedaCurp } from './formulario/BusquedaCurp';
import * as Yup from 'yup';
import 'yup-phone';
import locale from 'constants/yupLocale';
import { setLocale } from 'yup';
import { success, danger } from 'utils/Alerts';
import { AlertaFolio } from './formulario/AlertaFolio';
import { createRegistro } from 'services/solicitudService';
import { useNavigate } from 'react-router-dom';
// ** Recaptcha
import RecaptchaProvider from 'components/Recaptcha';
import useRecaptcha from 'components/Recaptcha/useRecaptcha';

setLocale(locale);

const validationSchema = [
  Yup.object().shape({
    curp: Yup.string().required().length(18),
  }),
  Yup.object().shape({}),
  Yup.object().shape({
    curp: Yup.string().length(18),
    nombres: Yup.string().required().max(250),
    apellido_paterno: Yup.string().required().max(250),
    apellido_materno: Yup.string().max(250),
    nacimiento: Yup.date().required().nullable(),
    entidad: Yup.string().required(),
    genero: Yup.string().required().oneOf([`M`, `H`, `NA`]),
    edad: Yup.string().max(3),
    nacionalidad: Yup.string().required().max(250),
    municipio: Yup.string().required().max(250),
    colonia: Yup.string().required().max(250),
    cp: Yup.string().required().max(250),
    celular: Yup.string()
      .required()
      .matches(
        /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
        `Este campo debe ser un número de celular válido.`
      ),
    email: Yup.string().max(250),
    telefono: Yup.string().matches(
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
      `Este campo debe ser un número de teléfono válido.`
    ),
    calle: Yup.string().required().max(250),
    vialidad: Yup.string().required().max(250),
    num_ext: Yup.string().required().max(250),
    num_int: Yup.string().max(250),
    letra_ext: Yup.string().max(250),
    letra_int: Yup.string().max(250),
    terminos: Yup.boolean().required(),
  }),
];

const initialValues = {
  curp: ``,
  nombres: ``,
  apellido_paterno: ``,
  apellido_materno: ``,
  nacimiento: null,
  entidad: ``,
  genero: ``,
  edad: ``,
  nacionalidad: ``,
  municipio: ``,
  colonia: ``,
  cp: ``,
  celular: ``,
  email: ``,
  telefono: ``,
  calle: ``,
  vialidad: ``,
  num_ext: ``,
  num_int: ``,
  letra_ext: ``,
  letra_int: ``,
  terminos: false,
};

const Solicitud = () => {
  const { getRecaptchaToken } = useRecaptcha();
  const [disabledFields, setDisabledFields] = useState([]);
  const navigate = useNavigate();
  const steps = [
    {
      title: `Pre-registrate y forma parte de Nidos de Lluvia.`,
      element: <BusquedaCurp setDisabledFields={setDisabledFields} />,
      hideActions: true,
    },
    {
      title: `Datos encontrados del ciudadano`,
      element: <DatosSolicitante />,
      resetOnCancel: true,
    },
    {
      title: `Pre-registro para solicitar el apoyo`,
      element: <Preregistro disabledFields={disabledFields} />,
      resetOnCancel: true,
      activateWhen: {
        terminos: true,
      },
    },
  ];

  const onSave = async (values) => {
    const token = await getRecaptchaToken();
    await createRegistro(values, token)
      .then((response) => {
        const { data } = response.data;
        success(
          <AlertaFolio folio={data.folio} />,
          () => {
            window.scrollTo({ top: 0, behavior: `smooth` });
            navigate(`/`);
          },
          null,
          {
            title: `¡Ya registramos tus datos!`,
          }
        );
      })
      .catch((e) => {
        danger(e.response?.data?.message ?? e.message);
      });
  };

  return (
    <div className="flex flex-col flex-1 bg-gray-50 min-h-full">
      <FormStepper
        onSave={onSave}
        steps={steps}
        initialValues={initialValues}
        validationSchema={validationSchema}
      />
    </div>
  );
};
/**
 * Formulario de solicitud con protección de Recaptcha
 */
const SolicitudRecaptcha = () => (
  <RecaptchaProvider>
    <Solicitud />
  </RecaptchaProvider>
);

export default SolicitudRecaptcha;
