import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Icon } from './Icon';
import './styles.css';
import { motion } from 'framer-motion';

const Mapa = ({ latitud, longitud }) => {
  if (!latitud || !longitud) {
    return null;
  }
  return (
    <motion.div
      className="w-full min-h-[387px] h-full"
      animate={{ opacity: 1, y: 0 }}
      initial={{ opacity: 0, y: 20 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.15 }}
    >
      <MapContainer
        center={[latitud, longitud]}
        zoom={17}
        scrollWheelZoom={false}
        style={{ height: `100%`, minHeight: `387px`, width: `100%` }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[latitud, longitud]} icon={Icon}>
          <Popup>
            <p className="font-montserrat-semibold text-[13px] text-primary-darker">{`${latitud}${longitud}`}</p>
          </Popup>
        </Marker>
      </MapContainer>
    </motion.div>
  );
};
export default Mapa;
