import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { DrawerLink } from './DrawerLink';

const drawerWidth = 240;

const DrawerHeader = styled(`div`)(({ theme }) => ({
  display: `flex`,
  alignItems: `center`,
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: `flex-end`,
}));

export const Drawer = ({ open, handleDrawerToggle, pages }) => (
  <MuiDrawer
    color="red"
    variant="temporary"
    open={open}
    onClose={handleDrawerToggle}
    ModalProps={{
      keepMounted: true,
    }}
    sx={{
      display: { xs: `block`, md: `none` },
      '& .MuiDrawer-paper': {
        boxSizing: `border-box`,
        width: drawerWidth,
        backgroundColor: `primary.lightest`,
      },
    }}
  >
    <DrawerHeader>
      <IconButton onClick={handleDrawerToggle}>
        <ChevronLeftIcon />
      </IconButton>
    </DrawerHeader>
    <Divider />
    <List disablePadding>
      {pages.map(({ label, to, hash, ...page }) => (
        <DrawerLink
          key={label}
          label={label}
          to={to}
          hash={hash}
          handleDrawerToggle={handleDrawerToggle}
          {...page}
        />
      ))}
      <Divider />
      <DrawerLink
        label="Quiero solicitar el apoyo"
        to="/solicitud_apoyo"
        handleDrawerToggle={handleDrawerToggle}
      />
    </List>
  </MuiDrawer>
);
