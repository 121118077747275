import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ContainedButton } from 'components/Buttons';
import { ReactComponent as IcoTinaco } from 'assets/icons/ico_btn_tinaco.svg';
import ImgMapa from 'assets/images/img_mapa_2024.png';
import coloniasArray from './colonias';
import { fontSize } from '@mui/system';

const MapaSection = () => {
  const navigate = useNavigate();
  const [selectedMunicipio, setSelectedMunicipio] = useState('Zapopan');
  const [selectedRegion, setSelectedRegion] = useState('AMG');
  let municipioIndex = 0;

  const handleChangeRegion = (panel) => {
    setSelectedRegion(panel);
  };

  const handleChange = (panel) => {
    setSelectedMunicipio(panel);
  };

  return (
    <div className="flex flex-row flex-wrap xl2:flex-nowrap flex-auto w-full">
      <div className="w-full flex flex-row flex-wrap lg:flex-wrap py-[30px] xl:w-1/3">
        <div className="w-full font-nutmeg flex flex-col items-center">
          <h2 className="text-3xl md:text-4xl lg:text-[38px] text-primary-darker font-nutmeg-extrabold text-center xl2:text-left md:px-16 pb-7">
            Municipios 2024
          </h2>
          <div className="accordion-info inline-block w-3/4" style={{ minWidth: "330px" }}>
            {coloniasArray.map((card, index) => (
              <Accordion key={`region-${index}`} expanded={selectedRegion === card.region} onClick={() => handleChangeRegion(card.region)} disableGutters elevation={0} square sx={{ borderRadius: `10px !important` }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} className="m-0">
                  <h2 className={`text-[20px] lg:text-2xl font-montserrat-extrabold py-0 ml-1`} style={{ color: card.colorAccent }}>{card.region}</h2>
                </AccordionSummary>
                {card.municipios.map((m, indexM) => {
                  municipioIndex++;
                  return (
                  <Accordion key={`municipio-${indexM}`} expanded={selectedMunicipio === m.municipio} onClick={() => handleChange(m.municipio)} disableGutters elevation={0} square sx={{ borderRadius: `10px !important`, paddingLeft: `16px` }}>
                    <AccordionSummary 
                      expandIcon={<ExpandMoreIcon />} 
                      sx={{
                        minHeight: '30px !important',
                        '.MuiAccordionSummary-content': { margin: '0px !important' } 
                      }} 
                      className="m-0">
                        <h4 className="text-gray-700 font-montserrat-extrabold py-0 ml-1">
                          <div className='text-white mr-2 rounded-sm inline-block text-center' style={{ backgroundColor: card.colorAccent, width: "34px" }}>{String(municipioIndex).padStart(2, '0')}</div>
                          {m.municipio}
                        </h4>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: `0px 16px 16px !important` }}>
                      <div className="pl-8 text-sm font-montserrat leading-8 pr-6">
                        {m.colonias.map((c, indexC) => (
                          <li
                            style={{ '&::before': { fontSize: `26px` } }}
                            key={`colonia-${indexC}`}
                            className="text-gray-700 font-montserrat-semibold italic text-[16px]"
                          >
                            {c}
                          </li>
                        ))} 
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  );
                })}
              </Accordion>
            ))}
          </div>
          <div className="py-6 flex w-full justify-center mx-16">
            <ContainedButton
              color="secondary"
              textColor="#021E43"
              onClick={() => navigate(`/solicitud_apoyo`)}
              className="w-full md:w-2/3 py-6 max-w-[380px]"
              sx={{
                fontWeight: `bold`,
                fontSize: `18px`,
                display: `flex`,
              }}
            >
              <IcoTinaco className="min-w-[32px] min-h-[32px] mr-5" />
              Solicita un nido de lluvia
            </ContainedButton>
          </div>
        </div>
      </div>
      <div className="w-full xl:w-2/3 scroll-mt-[64px]" id="mapa">
        <img
          className="object-contain w-full"
          src={ImgMapa}
          alt="Ubicaciones colonias por municipio"
        />
      </div>
    </div>
  );
};
export default MapaSection;
