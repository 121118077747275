import { differenceInYears, isValid } from 'date-fns';

const calculateAge = (birthdate) => {
  if (!birthdate || !isValid(birthdate)) {
    return ``;
  }
  const today = new Date();
  return differenceInYears(today, birthdate);
};

export { calculateAge };
