import { TextField } from 'components/FormComponents';
import { Field, FastField } from 'formik';

const FormComponent = ({ field, meta, ...props }) => (
  <TextField
    {...field}
    error={!!(meta.touched && meta.error)}
    helperText={meta.touched && meta.error}
    {...props}
  />
);

export const FormInput = ({ fast = true, ...props }) => {
  if (fast) {
    return (
      <FastField name={props.name}>
        {({ field, meta }) => <FormComponent field={field} meta={meta} {...props} />}
      </FastField>
    );
  }

  return (
    <Field name={props.name}>
      {({ field, meta }) => <FormComponent field={field} meta={meta} {...props} />}
    </Field>
  );
};
