import { Container } from '@mui/material';
import { ReactComponent as IcoProceso1 } from 'assets/icons/ico_1_proceso.svg';
import { ReactComponent as IcoProceso2 } from 'assets/icons/ico_2_proceso.svg';
import { ReactComponent as IcoProceso3 } from 'assets/icons/ico_3_proceso.svg';
import { ReactComponent as IcoProceso4 } from 'assets/icons/ico_4_proceso.svg';
import { ReactComponent as IcoProceso5 } from 'assets/icons/ico_5_proceso.svg';

const processArray = [
  {
    title: `Se recolecta el agua de lluvia `,
    text: `con una conexión en el techo de la vivienda`,
    icon: <IcoProceso1 />,
  },
  {
    title: `El agua cae en un separador de primeras aguas, `,
    text: `para garantizar que la más limpia sea la que pase al sistema`,
    icon: <IcoProceso2 />,
  },
  {
    title: `El agua se canaliza hacia un sistema de almacenamiento `,
    text: `de hasta 2,500 litros donde mediante una serie de componentes, se filtra y potabiliza el agua`,
    icon: <IcoProceso3 />,
  },
  {
    title: `La fuente de abastecimiento se conecta a una llave `,
    text: `para que puedan usarla en la vivienda`,
    icon: <IcoProceso4 />,
  },
  {
    title: `A través de un filtro purificador se potabiliza el agua `,
    text: `para consumo humano `,
    icon: <IcoProceso5 />,
  },
];

const FuncionamientoSection = () => (
  <div className="bg-[#0A3356]">
    <Container maxWidth={`xl3`}>
      <div className=" w-full flex flex-col flex-wrap py-[62px]">
        <Container maxWidth={`xl`}>
          <div className="w-full font-nutmeg">
            <h2 className="text-4xl md:text-5xl lg:text-[52px] text-white text-center font-nutmeg-extrabold">
              ¿Cómo funcionan los Nidos de Lluvia?
            </h2>
            <p className="text-xl lg:text-2xl text-white text-center py-10 !leading-[3rem]">
              El sistema consiste en
              <b>
                {` la conexión del techo de la vivienda para canalizar el agua de lluvia hacia un
              almacenaje `}
              </b>
              y, mediante una serie de componentes,
              <b>
                {` `}limpiar el agua para obtener una fuente de abastecimiento de agua de buena
                calidad y potable.
              </b>
              <br />
              <br />
              <span className="font-montserrat-medium text-white">
                Con esta agua se pueden realizar actividades domésticas como lavar ropa, trastes,
                bañarse y cualquier necesidad del hogar.
              </span>
            </p>
          </div>
        </Container>
        <div className="flex flex-row justify-around items-center flex-wrap mt-[20px] gap-3 w-full">
          {processArray.map((card, index) => (
            <div key={`process-${index + 1}`}>
              <div className="w-[190px] h-[190px] m-auto flex justify-center">{card?.icon}</div>
              <div className="flex justify-center items-center py-10">
                <span className="w-[44px] h-[44px]  bg-[#0595F0] rounded-full  ">
                  <p className="text-[28px] text-white font-montserrat-bold flex justify-center items-center">
                    {index + 1}
                  </p>
                </span>
              </div>
              <p className="text-lg md:text-[20px] text-center text-primary-medium-light max-w-[300px] min-h-[220px] !leading-[rem] font-montserrat">
                <span className="font-montserrat-bold">{card?.title}</span>
                <span className="text-white">{card?.text}</span>
              </p>
            </div>
          ))}
        </div>
      </div>
    </Container>
  </div>
);
export default FuncionamientoSection;
