import { Link } from '@mui/material';

export const AlertaFolio = ({ folio = `R-GDL-03-0001-22` }) => (
  <div>
    <h2 className="text-[22px] montserrat-medium text-primary-light pb-4">
      FOLIO: <b>{folio}</b>
    </h2>
    <div className="font-montserrat text-[14px] text-left leading-6">
      <p>
        <b className="font-montserrat-medium">Te esperamos en la Junta Comunitaria</b>, que es
        requisito indispensable para acceder al beneficio.
        <br />
        <Link
          sx={{ color: `primary.light`, fontFamily: `Montserrat Bold` }}
          href="/calendario_juntas"
          target="_blank"
          rel="noopener noreferrer"
        >
          Checa aquí
        </Link>
        {` `}para acudir a la más cercana a tu domicilio.
        <br />
        <br />
        <b>Recuerda traer los siguientes documentos:</b>
        <br />
      </p>
      <ol className="list-decimal">
        <li>1. Constancia CURP.</li>
        <li>
          2. Identificación oficial vigente con fotografía (INE, IMSS, licencia de conducir,
          pasaporte o cartilla militar).
        </li>
        <li>3. Comprobante de domicilio con vigencia de 90 días.</li>
      </ol>
    </div>
  </div>
);
