// ** React Import
import './styles.scss';
import { useRef, memo } from 'react';

// ** Full Calendar & it's Plugins
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';

import { Card, CardBody } from 'reactstrap';

const Calendar = (props) => {
  const calendarRef = useRef(null);
  const { setSelectedEvent, events, handleOpenDialog } = props;

  // ** calendarOptions(Props)
  const calendarOptions = {
    events: events,
    eventTimeFormat: {
      hour: `2-digit`,
      minute: `2-digit`,
      meridiem: true,
    },
    plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
    initialView: `dayGridMonth`,
    headerToolbar: {
      start: `prev, next`,
      end: `title`,
    },
    editable: true,
    eventResizableFromStart: true,
    dragScroll: true,
    dayMaxEvents: 4,
    navLinks: false,

    datesSet({ view }) {
      const title = document.getElementsByClassName(`fc-toolbar-title`);
      if (title?.length > 0) {
        title[0].firstChild.textContent = `${view?.title?.replace(`de`, ``)}`;
      }
    },

    eventClick(arg) {
      handleOpenDialog();
      setSelectedEvent(arg.event.extendedProps);
    },

    eventContent(arg) {
      const argEvent = arg.event.extendedProps;
      console.log(argEvent);
      //const municipio = argEvent.municipio.substr(0, 3);
      //const meridiem = arg.event.start.getUTCHours() < 12 ? `am` : `pm`;

      const italicEl = document.createElement(`div`);
      italicEl.setAttribute(`style`, `color: #0D2C43; white-space: pre-wrap; font-size: 11px;`);
      italicEl.innerHTML = `${String(arg.event.start.getUTCHours()).padStart(2, `0`)}:${String(
        arg.event.start.getUTCMinutes()
      ).padStart(2, `0`)} <span style="color: #0595F0; text-transform: uppercase;
      ">${argEvent.municipio_afijo}-</span>`;

      const italicEl2 = document.createElement(`div`);
      italicEl2.setAttribute(
        `style`,
        `overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;`
      );
      italicEl2.innerHTML = `<span style="white-space: pre-wrap; font-size: 11px;">${argEvent.colonia}<span/>`;

      const arrayOfDomNodes = [italicEl, italicEl2];
      return { domNodes: arrayOfDomNodes };
    },
    ref: calendarRef,
  };

  return (
    <Card className="shadow-none border-0 mb-0 rounded-0">
      <CardBody className="pb-0 overflow-auto">
        <FullCalendar locale={esLocale} {...calendarOptions} />
      </CardBody>
    </Card>
  );
};

export default memo(Calendar);
