import { Container } from '@mui/material';
import { ReactComponent as IcoTinaco } from 'assets/icons/ico_tinaco.svg';
import { ReactComponent as IcoViviendas } from 'assets/icons/ico_viviendas.svg';
import { ReactComponent as IcoLocation } from 'assets/icons/ico_location.svg';
import { ReactComponent as IcoMoney } from 'assets/icons/ico_money.svg';
import CardResult from '../ResultadosSection/CardResult';
const resultsArray = [
  {
    title: `3,500`,
    subtitle: `Nidos a instalar de manera gratuita`,
    icon: <IcoTinaco style={{ height: '80px' }} />,
    alt: `3500 Nidos a instalar de manera gratuita`,
  },
  {
    title: `+ 15,000`,
    subtitle: `Personas con agua potable`,
    icon: <IcoViviendas style={{ height: '80px' }} />,
    alt: `+ 15,000 personas con agua potable`,
  },
  {
    title: `14 municipios`,
    subtitle: `Beneficiados`,
    icon: <IcoLocation style={{ height: '80px' }} />,
    alt: `14 municipios beneficiados`,
  },
  {
    title: `80 mdp`,
    subtitle: `de inversión`,
    icon: <IcoMoney style={{ height: '80px' }} />,
    alt: `80 mdp de inversión`,
  },
];

const EtapaSection = () => (
  <div className="bg-[#0A3356] py-[70px] scroll-mt-[64px]" id="convocatoria">
    <Container maxWidth={`xl3`}>
      <div className=" w-full flex flex-col flex-wrap">
        <div className="w-full font-nutmeg max-w-[1041px] m-auto pb-[90px]">
          <p className="text-4xl md:text-5xl lg:text-[52px] text-center text-white font-nutmeg-extrabold">
            <b>
              Etapa
              <span className="text-[#0595F0]"> 2024</span>
            </b>
          </p>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl2:grid-cols-4 gap-x-4 gap-y-6 w-full'>
          {resultsArray.map((card, index) => (
            <div key={`result-${index + 1}`} className="flex justify-center">
            <CardResult item={card} wrap={index === 3} />
            </div>
          ))}
        </div>
        <div className=" w-full flex flex-col flex-wrap pt-[60px]">
          <div className="w-full font-montserrat">
            <p className="text-xl lg:text-2xl text-white text-center py-10">
              * Los municipios seleccionados
              <b>{` son resultado de un análisis de vulnerabilidad hídrica en Jalisco.`}</b>
            </p>
          </div>
        </div>
      </div>
    </Container>
  </div>
);
export default EtapaSection;
