import FormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckbox from '@mui/material/Checkbox';
import clsx from 'clsx';

export const Checkbox = ({ className, value, label, color, ...props }) => (
  <div className={clsx(`w-full my-2`, className)}>
    <FormControlLabel
      control={<MuiCheckbox color={color} checked={!!value} {...props} />}
      label={label}
    />
  </div>
);
