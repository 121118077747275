import { Outlet } from 'react-router-dom';
import AppBar from 'components/Appbar';
import Footer from 'components/Footer';

const MainLayout = () => (
  <div className="flex flex-col flex-auto min-h-screen min-w-0 relative z-0">
    <AppBar />
    <div className="flex flex-col flex-auto min-h-0 relative z-10">
      <Outlet />
    </div>
    <Footer />
  </div>
);

export default MainLayout;
