import { Container, Link } from '@mui/material';
import InformateSection from 'views/Home/InformateSection';
import { ContainedButton } from 'components/Buttons';
import pdf from 'documents/mantenimiento_NDLL.pdf';
import pdfAprovecha from 'documents/Aprovecha_nido.pdf';
import contenidoJuntas from 'documents/Contenidos_juntas.pdf';
import dispositivos from 'documents/NDLL_MANUAL_DISPOSITIVOS.pdf';
import img1 from 'assets/images/matenimiento_1.svg';
import img2 from 'assets/images/matenimiento_2.svg';
import img3 from 'assets/images/matenimiento_3.svg';
import img4 from 'assets/images/matenimiento_4.svg';

export const CuidaTuNido = () => {
  return (
    <div className="flex flex-col flex-1 bg-gray-50 min-h-full">
      <div className="flex flex-1 w-full sm:p-6">
        <Container className="flex flex-1 py-7 justify-center items-center" maxWidth="xl">
            <h2 className="text-4xl md:text-5xl lg:text-[52px] text-primary-darker font-nutmeg-extrabold mb-10">
                Cuida tu Nido de lluvia
            </h2>
            <h2 className="text-3xl text-[#0595F0] font-nutmeg-extrabold mb-10">
                Lo más importante
            </h2>
            <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 text-center font-bold font-nutmeg-extrabold mb-4'>
                <div className='flex flex-col items-center justify-start'>
                    <img src={img1} alt='img' className='mb-10'></img>
                    <div className='flex rounded-full bg-[#0595F0] w-10 font-bold text-[24px] h-10 text-white p-2 items-center justify-center mb-12'>
                        1
                    </div>
                    <p className='mb-10 text-[#0D2C43]'>Techo siempre despejado y limpio.</p>
                </div>
                <div className='flex flex-col items-center justify-start'>
                    <img src={img2} alt='img' className='mb-10'></img>
                    <div className='flex rounded-full bg-[#0595F0] w-10 font-bold text-[24px] h-10 text-white p-2 items-center justify-center mb-12'>
                        2
                    </div>
                    <p className='mb-10 text-[#0D2C43]'>Tlaloque vacío antes de cada lluvia.</p>
                </div>
                <div className='flex flex-col items-center justify-start'>
                    <img src={img3} alt='img' className='mb-10'></img>
                    <div className='flex rounded-full bg-[#0595F0] w-10 font-bold text-[24px] h-10 text-white p-2 items-center justify-center mb-12'>
                        3
                    </div>
                    <p className='mb-10 text-[#0D2C43]'>Almacenamiento de agua siempre clorado.</p>
                </div>
                <div className='flex flex-col items-center justify-start'>
                    <img src={img4} alt='img' className='mb-10'></img>
                    <div className='flex rounded-full bg-[#0595F0] w-10 font-bold text-[24px] h-10 text-white p-2 items-center justify-center mb-12'>
                        4
                    </div>
                    <p className='mb-10 text-[#0D2C43]'>Filtros siempre limpios.</p>
                </div>
            </div>
            <div className='w-full flex-col md:flex-row flex items-center justify-center'>
                <ContainedButton
                    color="alternativeButton"
                    textColor="#0066A7"
                    className="w-full md:pl-[15px] md:pr-[15px] py-10 text-center"
                    sx={{
                        paddingY: `12px`,
                        fontSize: { md: `16px`, lg: `20px` },
                        fontWeight: `bold`,
                        fontFamily: `Montserrat SemiBold`,
                    }}
                    component={Link}
                    href={pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    Descarga el manual de mantenimiento
                </ContainedButton>
                <ContainedButton
                    color="alternativeButton"
                    textColor="#0066A7"
                    className="w-full md:pl-[15px] md:pr-[15px] py-10 text-center"
                    sx={{
                        paddingY: `12px`,
                        fontSize: { md: `16px`, lg: `20px` },
                        fontWeight: `bold`,
                        fontFamily: `Montserrat SemiBold`,
                    }}
                    component={Link}
                    href={dispositivos}
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    Dispositivos para ahorrar agua
                </ContainedButton>
                <ContainedButton
                    color="alternativeButton"
                    textColor="#0066A7"
                    className="w-full md:pl-[15px] md:pr-[15px] py-10 text-center"
                    sx={{
                        paddingY: `12px`,
                        fontSize: { md: `16px`, lg: `20px` },
                        fontWeight: `bold`,
                        fontFamily: `Montserrat SemiBold`,
                    }}
                    component={Link}
                    href={pdfAprovecha}
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    Aprovecha tu Nido en casa
                </ContainedButton>
                <ContainedButton
                    color="alternativeButton"
                    textColor="#0066A7"
                    className="w-full md:pl-[15px] md:pr-[15px] py-10 text-center"
                    sx={{
                        paddingY: `12px`,
                        fontSize: { md: `16px`, lg: `20px` },
                        fontWeight: `bold`,
                        fontFamily: `Montserrat SemiBold`,
                    }}
                    component={Link}
                    href={contenidoJuntas}
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    Contenidos Juntas Comunitarias
                </ContainedButton>
            </div>
        </Container>
      </div>
      <InformateSection />
    </div>
  );
};
