import {
  useCallback,
  useMemo,
  useState,
  useEffect,
  Fragment,
  isValidElement,
  cloneElement,
} from 'react';
import { Container } from '@mui/material';
import { motion } from 'framer-motion';
import IDJaliscologo from 'assets/logos/logo_id_jalisco.png';
import { ContainedButton } from 'components/Buttons';
import { Formik } from 'formik';
import * as Yup from 'yup';

export const FormStepper = ({ steps = [], onCancel, onSave, validationSchema, initialValues }) => {
  const [index, setIndex] = useState(0);
  const isFirstPage = useMemo(() => index === 0, [index, steps]);
  const isLastPage = useMemo(() => index === steps?.length - 1, [index, steps]);
  const currentSchema = useMemo(
    () => (validationSchema ? validationSchema[index] : Yup.object().shape({})),
    [index, steps]
  );
  const title = useMemo(() => steps[index]?.title ?? ``, [index, steps]);
  const hideActions = useMemo(() => steps[index]?.hideActions ?? false, [index, steps]);

  useEffect(() => {
    setIndex(0);
  }, [steps]);

  const handleForward = ({ values, actions }, step) => {
    if (isLastPage) {
      onSave(values, actions);
    } else {
      actions?.setSubmitting(false);
      actions?.setTouched({});
      setIndex(step ?? index + 1);
    }
  };

  const handleBack = useCallback(
    (resetForm) => {
      // Last step
      if (isFirstPage) {
        onCancel();
        return;
      }
      // Reset form
      if (steps[index]?.resetOnCancel) {
        resetForm();
        setIndex(0);
        return;
      }
      // Go back
      setIndex(index - 1);
    },
    [index]
  );

  const disableForward = useCallback(
    (values) => {
      const vals = steps[index]?.activateWhen;
      if (!vals) {
        return false;
      }
      return Object.entries(vals)?.some(([key, item]) => values[key] !== item);
    },
    [index, steps]
  );

  return (
    <Formik
      validateOnMount
      validationSchema={currentSchema}
      validateOnChange
      initialValues={initialValues}
      onSubmit={(values, actions) => handleForward({ values, actions })}
      enableReinitialize
    >
      {({ handleSubmit, resetForm, values, ...actions }) => (
        <div className="flex flex-1 w-full sm:p-6">
          <Container
            className="flex flex-1 shadow rounded-lg bg-white py-7 md:!px-14"
            maxWidth="lg"
          >
            <motion.div
              className="flex flex-row justify-end md:justify-between max-w-full flex-wrap md:flex-nowrap"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <h1 className="text-2xl font-montserrat-bold text-primary-darker">{title}</h1>
              <img
                className="object-contain w-[110px] h-[36px]"
                src={IDJaliscologo}
                alt="logo ID Jalisco"
              />
            </motion.div>
            <div className="flex-1 ">
              {steps.map(({ element }, i) => {
                if (!isValidElement(element)) {
                  return null;
                }
                return (
                  <Fragment key={i}>
                    {index === i && (
                      <motion.div
                        className="w-full"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                      >
                        <div style={{ minWidth: 0, minHeight: 0 }}>
                          {cloneElement(element, {
                            handleSubmit,
                            handleBack,
                            handleForward: (page) => handleForward({ values, actions }, page),
                          })}
                        </div>
                      </motion.div>
                    )}
                  </Fragment>
                );
              })}
            </div>
            {!hideActions && (
              <div
                className="flex flex-1 w-full items-center flex-wrap justify-center gap-10"
                style={{ padding: `10px 20px`, minHeight: 70 }}
              >
                <ContainedButton
                  color="secondaryButton"
                  textColor="#7E84A3"
                  onClick={() => handleBack(resetForm)}
                >
                  Regresar
                </ContainedButton>
                <ContainedButton
                  color="formButton"
                  onClick={handleSubmit}
                  disabled={disableForward(values)}
                >
                  Continuar
                </ContainedButton>
              </div>
            )}
          </Container>
        </div>
      )}
    </Formik>
  );
};
