import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

const labelStyle = {
  fontFamily: `Montserrat Bold`,
  transition: `all 0.3s ease`,
  color: `white`,
  '&:hover,&:focus': {
    color: `primary.light`,
  },
  fontSize: 11,
  py: `3px`,
  my: `0px`,
};

export const PopupLink = ({ label, to, external }) => {
  if (external) {
    return (
      <MenuItem
        role={undefined}
        component={Link}
        key={label}
        href={to}
        sx={labelStyle}
        target="_blank"
        rel="noopener noreferrer"
        tabIndex={0}
      >
        {label}
      </MenuItem>
    );
  }

  return (
    <MenuItem
      role={undefined}
      component={RouterLink}
      key={label}
      to={to}
      sx={labelStyle}
      tabIndex={0}
    >
      {label}
    </MenuItem>
  );
};
