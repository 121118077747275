import { useMemo, useEffect } from 'react';
import { FormSection } from 'components/FormStepper/FormSection';
import { Divider, Link } from '@mui/material';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PlaceIcon from '@mui/icons-material/Place';
import {
  FormInput,
  FormAutocomplete,
  FormCheckbox,
  FormDatePicker,
} from 'components/FormikComponents';
import { useCatalog } from 'hooks/useCatalog';
import { useFormikContext } from 'formik';
import pdf from '../../../documents/aviso_privacidad_NDLL.pdf';
import { calculateAge } from 'utils/dateUtils';
import { HashLink } from 'react-router-hash-link';

const catalogoSexo = [
  { name: `Mujer`, value: `M` },
  { name: `Hombre`, value: `H` },
  { name: `Prefiero no contestar`, value: `NA` },
];

const AvisoPrivacidad = () => (
  <p className="font-montserrat-semibold text-[14px]">
    He leído y acepto los términos y condiciones del{` `}
    <Link
      sx={{ color: `formButton.main` }}
      href={pdf}
      underline="hover"
      target="_blank"
      rel="noopener noreferrer"
    >
      Aviso de privacidad *
    </Link>
  </p>
);

export const Preregistro = ({ disabledFields }) => {
  const { values, setFieldValue } = useFormikContext();
  const vialidadCatalog = useCatalog(`tipo_vialidad`);
  const entidadCatalog = useCatalog(`entidades`, { value: `id`, name: `nombre` });
  const nacionalidadCatalog = useCatalog(`nacionalidad`);
  const municipioCatalog = useCatalog(`municipios`, { value: `id`, name: `nombre` });
  const coloniasCatalog = useMemo(() => {
    const municipioActual = municipioCatalog?.find((item) => item.id === values.municipio);
    if (!municipioActual) {
      return [];
    }
    return municipioActual?.colonias?.map(
      (item) =>
        ({
          value: item.id,
          name: item.nombre,
        } ?? [])
    );
  }, [values.municipio, municipioCatalog]);

  useEffect(() => {
    if (!values.nacimiento) {
      return;
    }
    setFieldValue(`edad`, calculateAge(values.nacimiento));
  }, [values.nacimiento]);

  return (
    <form>
      <FormSection Icon={GroupOutlinedIcon} title="Información del solicitante">
        <FormInput
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="CURP"
          name="curp"
          disabled={disabledFields?.includes(`curp`)}
          inputProps={{ style: { textTransform: `uppercase` } }}
        />
        <div className="hidden sm:flex sm:w-1/2 md:w-1/3" />
        <div className="hidden md:flex sm:w-1/2 md:w-1/3" />
        <FormInput
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Nombre(s)"
          name="nombres"
          disabled={disabledFields?.includes(`nombres`)}
          required
        />
        <FormInput
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Primer Apellido"
          name="apellido_paterno"
          disabled={disabledFields?.includes(`apellido_paterno`)}
          required
        />
        <FormInput
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Segundo Apellido"
          name="apellido_materno"
          disabled={disabledFields?.includes(`apellido_materno`)}
        />
        <FormDatePicker
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Fecha de nacimiento"
          name="nacimiento"
          disabled={disabledFields?.includes(`nacimiento`)}
          required
          disableFuture
        />
        <FormAutocomplete
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Entidad de nacimiento"
          name="entidad"
          options={entidadCatalog}
          disabled={disabledFields?.includes(`entidad`)}
          required
        />
        <FormAutocomplete
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Sexo registrado al nacimiento"
          name="genero"
          options={catalogoSexo}
          required
          disabled={disabledFields?.includes(`genero`)}
        />
        <FormInput className="w-full px-3 sm:w-1/2 md:w-1/3" label="Edad" name="edad" disabled />
        <FormAutocomplete
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Nacionalidad de nacimiento"
          name="nacionalidad"
          options={nacionalidadCatalog.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          )}
          required
          disabled={disabledFields?.includes(`nacionalidad`)}
        />
        <div className="w-full mt-8">
          <Divider light />
        </div>
      </FormSection>
      <FormSection Icon={PlaceIcon} title="Información de contacto">
        <p className="font-montserrat-bold text-[12px] text-primary-darker py-3">
          {`Únicamente podrán acceder al pre-registro si se encuentran en los municipios y colonias que participan en el programa: `}
          &nbsp;&nbsp;
          <Link
            sx={{
              color: `#0595F0`,
              textDecorationColor: `#0595F0`,
              fontFamily: `Nutmeg Regular`
            }}
            component={HashLink}
            target="_blank"
            rel="noopener noreferrer"
            to="/#mapa"
            style={{ whiteSpace: `nowrap` }}
          >
            Ver el mapa.
          </Link>
        </p>
        <FormAutocomplete
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Municipio"
          name="municipio"
          options={municipioCatalog}
          required
        />
        <FormAutocomplete
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Colonia / Localidad"
          name="colonia"
          options={coloniasCatalog}
          required
        />
        <FormInput
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Código Postal"
          name="cp"
          required
        />
        <FormInput
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Celular"
          name="celular"
          required
        />
        <FormInput
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Correo electrónico"
          name="email"
        />
        <FormInput className="w-full px-3 sm:w-1/2 md:w-1/3" label="Teléfono" name="telefono" />
        <FormInput className="w-full px-3 sm:w-1/2 md:w-1/3" label="Calle" name="calle" required />
        <FormAutocomplete
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Tipo de vialidad"
          name="vialidad"
          options={vialidadCatalog}
          required
        />
        <FormInput
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Número exterior"
          name="num_ext"
          required
        />
        <FormInput
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Número interior"
          name="num_int"
        />
        <FormInput
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Letra exterior"
          name="letra_ext"
        />
        <FormInput
          className="w-full px-3 sm:w-1/2 md:w-1/3"
          label="Letra interior"
          name="letra_int"
        />

        <FormCheckbox
          label={<AvisoPrivacidad />}
          color="formButton"
          name="terminos"
          className="flex justify-center"
        />
        <div className="w-full mt-8">
          <Divider light />
        </div>
      </FormSection>
    </form>
  );
};
