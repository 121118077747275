import { Container } from '@mui/material';
import Explicacion from 'assets/images/img_explicacion.png';

const ExplicationSection = () => (
  <div className="bg-[#ECEEEF] py-20">
    <div>
      <Container maxWidth={`xl2`}>
        <img
          className="object-contain w-[1420px] h-[638px] pointer-events-none"
          src={Explicacion}
          alt="Explicacion proceso"
        />
      </Container>
    </div>
  </div>
);
export default ExplicationSection;
