import { createTheme } from '@mui/material';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 475, // => @media (min-width: 475) { ... }
      sm: 640, // => @media (min-width: 640) { ... }
      md: 768, // => @media (min-width: 768) { ... }
      lg: 1024, // => @media (min-width: 1024) { ... }
      xl: 1280, // => @media (min-width: 1280) { ... }
      xl2: 1536, // => @media (min-width: 1536) { ... }
      xl3: 1792, // => @media (min-width: 1792) { ... }
      w475: 475, // => @media (min-width: 475) { ... }
      w640: 640, // => @media (min-width: 640) { ... }
      w768: 768, // => @media (min-width: 768) { ... }
      w800: 800, // => @media (min-width: 800) { ... }
      w1024: 1024, // => @media (min-width: 1024) { ... }
      w1280: 1280, // => @media (min-width: 1280) { ... }
      w1360: 1360, // => @media (min-width: 1360) { ... }
      w1440: 1440, // => @media (min-width: 1440) { ... }
      w1536: 1536, // => @media (min-width: 1536) { ... }
      w1640: 1640, // => @media (min-width: 1640) { ... }
      w1700: 1700, // => @media (min-width: 1700) { ... }
      w1800: 1800, // => @media (min-width: 1800) { ... }
    },
  },
  palette: {
    primary: {
      lightest: `#EDF4FF`,
      lighter: `#3EC8FC`,
      light: `#0597F2`,
      main: `#215384`,
      dark: `#021E43`,
      darker: `#0D2C43`,
      darkest: `#172A3C`,
    },
    secondary: {
      light: `#56DBD6`,
      main: `#69E9CE`,
    },
    gray: {
      50: `#F8FBFF`,
    },
    formButton: {
      main: `#2AB6E5`,
    },
    secondaryButton: {
      main: `#D7DFE0`,
    },
    alternativeButton: {
      main: `#76D9FF`,
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: `xl`,
      },
    },
  },
});
