import { CardMedia, Link } from '@mui/material';
import img from 'assets/images/img_quien.png';
import { ReactComponent as IcoLocation } from 'assets/icons/ico_location.svg';
import { ReactComponent as IcoOk } from 'assets/icons/ico_ok.svg';
import { ReactComponent as IcoViviendas } from 'assets/icons/ico_viviendas.svg';
import { ReactComponent as IcoConvocatoria } from 'assets/icons/ico_convocatoria.svg';
import { ContainedButton } from 'components/Buttons';
import { HashLink } from 'react-router-hash-link';
import pdf from 'documents/NIDOS_DE_LLUVIA_CONVOCATORIA_2024.pdf';

const Wrapper = ({ icon, children }) => (
  <div className="flex items-center  text-xl lg:text-2xl text-primary-medium-dark flex-wrap md:flex-nowrap md:justify-start justify-center py-2 md:!leading-[3rem]">
    <div className="flex items-center">{icon}</div>
    <span className="pl-6">{children}</span>
  </div>
);
const AccederSection = () => (
  <div className="bg-[#EDF4FF] flex flex-row flex-wrap xl2:flex-nowrap">
    <div className="w-full lg:w-1/3 flex justify-center">
      <CardMedia
        component="img"
        height="100%"
        image={img}
        alt="=Quién puede acceder a un nido de lluvia?"
      />
    </div>
    <div className="w-full flex flex-row flex-wrap py-10 font-nutmeg lg:w-2/3">
      <div className="px-16 max-w-[1024px]">
        <h2 className="text-4xl md:text-5xl lg:text-[52px] text-primary-darker font-nutmeg-bold w-full md:!leading-[70px] max-w-[650px]">
          <span className="font-nutmeg-black">¿Quién puede acceder</span> a Nidos de Lluvia 2024?
        </h2>
        <p className="text-xl lg:text-2xl text-primary-medium-dark py-10 md:!leading-[3rem]">
          Familias de los 14 municipios de Jalisco participantes que reúnan los siguientes requisitos:
        </p>
        <Wrapper icon={<IcoLocation className="max-w-[45px] max-h-[45px] m-2" />}>
          <span style={{ fontWeight: `bold` }}>
          Vivir en alguna de las colonias señaladas en el {` `}
          <Link
            sx={{
              color: `#0595F0`,
              textDecorationColor: `#0595F0`,
              fontFamily: `Nutmeg Regular`,
            }}
            component={HashLink}
            to="#mapa"
          >
            listado.
          </Link>
          </span>
        </Wrapper>
        <Wrapper  icon={<IcoOk className="max-w-[45px] max-h-[45px] m-2" />}>
          <span style={{ fontWeight: `bold` }}>Asiste a la junta comunitaria de tu colonia</span> con tu identificación oficial y comprobante de domicilio. Ahí podrás iniciar el proceso para solicitar tu Nido de Lluvia.
        </Wrapper>

        <Wrapper icon={<IcoViviendas className="max-w-[45px] max-h-[45px] m-2" />}>
          <span style={{ fontWeight: `bold` }}>Contar con las condiciones necesarias para la instalación</span> del sistema.
        </Wrapper>
        <Wrapper icon={<IcoConvocatoria className="max-w-[45px] max-h-[45px] m-2" />}>
          <span style={{ fontWeight: `bold` }}>Cumplir con los detalles en la convocatoria</span> publicada.
        </Wrapper>
        <ContainedButton
          color="alternativeButton"
          textColor="#0066A7"
          className="w-full lg:w-2/3 md:pl-[75px] py-10"
          sx={{
            paddingY: `12px`,
            fontSize: { md: `18px`, lg: `22px` },
            fontWeight: `bold`,
            fontFamily: `Montserrat SemiBold`,
          }}
          component={Link}
          href={pdf}
          target="_blank"
          rel="noopener noreferrer"
        >
          Descarga la convocatoria
        </ContainedButton>
      </div>
    </div>
  </div>
);

export default AccederSection;
