import { Container, Link as MuiLink } from '@mui/material';
import Question from './Question';
import pdf from 'documents/NIDOS_DE_LLUVIA_CONVOCATORIA_2024.pdf';

const Link = ({ href, children }) => (
  <MuiLink sx={{ color: `primary.light` }} href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </MuiLink>
);

export const FAQ = () => {
  const questions = [
    {
      title: `¿Qué necesito para contar con un Nido de Lluvia en mi hogar?`,
      children: (
        <ul className="list-disc">
          <li>
            Vivir en una de las colonias con vulnerabilidad hídrica seleccionadas luego del análisis
            técnico realizado.
          </li>
          <li>
            Asistir a la junta comunitaria a realizar en la colonia correspondiente, donde se dará a
            conocer el funcionamiento de los Nidos.
          </li>
          <li>Contar con espacio suficiente y áreas de captación propicias para la instalación.</li>
          <li>
            Cumplir con los compromisos adquiridos en la visita técnica y reuniones de
            socialización.
          </li>
          <li>Proporcionar la documentación que se describe <a target="_blank" href={pdf} rel="noopener noreferrer"><span className='text-primary-light underline'>aquí</span>.</a></li>
        </ul>
      ),
    },
    {
      title: `¿Cuáles son las colonias en las que se instalarán los Nidos de Lluvia y cómo fueron seleccionadas?`,
      children: (
        <div>
          <ul>
            <li style={{ marginTop: '5px' }}>
              <span className='font-bold'>Área Metropolitana de Guadalajara:</span>
              <ul className="list-disc pl-6 leading-6">
                <li>El Salto</li>
                <ul className='list-disc pl-6 leading-6'>
                  <li>El Verde</li>
                  <li>San José del Castillo (El Castillo)</li>
                  <li>San José del Quince</li>
                  <li>Villas de Guadalupe</li>
                </ul>
              </ul>
              <ul className="list-disc pl-6 leading-6">
                <li>Guadalajara</li>
                <ul className='list-disc pl-6 leading-6'>
                  <li>1ro de Mayo</li>
                  <li>5 de Mayo</li>
                  <li>Aarón Joaquín</li>
                  <li>El Bethel</li>
                  <li>Fraccionamiento Arandas</li>
                  <li>Hermosa Provincia</li>
                  <li>Insurgentes 1ra Sección</li>
                  <li>Insurgentes 2da Sección</li>
                  <li>Insurgentes 3ra Sección</li>
                  <li>Lagos de Oriente</li>
                  <li>Lomas de Polanco</li>
                  <li>Lomas del Paraíso I Sección</li>
                  <li>Oblatos 1ra Sección</li>
                  <li>Oblatos 2da Sección</li>
                  <li>San José Río Verde 1ra Sección</li>
                  <li>San Ramón</li>
                  <li>Tetlán II</li>
                  <li>Tetlán</li>
                </ul>
              </ul>
              <ul className="list-disc pl-6 leading-6">
                <li>Ixtlahuacán de los Membrillos</li>
                <ul className='list-disc pl-6 leading-6'>
                  <li>Arena</li>
                  <li>Bajío o Barrio Pobre</li>
                  <li>Ojo de Agua</li>
                </ul>
              </ul>
              <ul className="list-disc pl-6 leading-6">
                <li>Juanacatlán</li>
                <ul className='list-disc pl-6 leading-6'>
                  <li>Cabecera Municipal</li>
                  <li>La Playa</li>
                  <li>Tateposco</li>
                </ul>
              </ul>
              <ul className="list-disc pl-6 leading-6">
                <li>Tlaquepaque</li>
                <ul className='list-disc pl-6 leading-6'>
                  <li>El Zalate</li>
                  <li>Francisco I Madero 2da Sección</li>
                  <li>La Cofradía</li>
                  <li>La Gigantera</li>
                  <li>La Loma</li>
                  <li>Nueva Santa María</li>
                  <li>Potrero del Sauz</li>
                  <li>San Pedrito</li>
                  <li>Tateposco</li>
                </ul>
              </ul>
              <ul className="list-disc pl-6 leading-6">
                <li>Tlajomulco</li>
                <ul className='list-disc pl-6 leading-6'>
                  <li>Arcos de San Sebastián</li>
                  <li>Balcones de Santa Anita</li>
                  <li>Cima del Sol</li>
                  <li>El Manantial</li>
                  <li>Jardines de San Sebastián</li>
                  <li>La Fortuna</li>
                  <li>La Gigantera</li>
                  <li>La Nueva Victoria</li>
                  <li>La Victoria</li>
                  <li>Lomas de San Agustín</li>
                  <li>Paseos del Valle</li>
                  <li>Puerta Real</li>
                  <li>Rancho Alegre</li>
                </ul>
              </ul>
              <ul className="list-disc pl-6 leading-6">
                <li>Tonalá</li>
                <ul className='list-disc pl-6 leading-6'>
                  <li>Arroyo de en Medio</li>
                  <li>El Vado</li>
                  <li>Jauja</li>
                  <li>Los Pajaritos</li>
                  <li>San Miguel La Punta</li>
                  <li>Santa Isabel</li>
                  <li>Santa Rosa</li>
                </ul>
              </ul>
              <ul className="list-disc pl-6 leading-6">
                <li>Zapopan</li>
                <ul className='list-disc pl-6 leading-6'>
                  <li>12 de Diciembre</li>
                  <li>Benito Juárez Norte</li>
                  <li>Brisas de la Primavera</li>
                  <li>El Rehilete</li>
                  <li>El Tizate</li>
                  <li>Indígena de Mezquitán</li>
                  <li>La Noria</li>
                  <li>Lomas (Vistas) del Centinela</li>
                  <li>Lomas del Refugio</li>
                  <li>Los Cerritos</li>
                  <li>Mesa Colorada Poniente</li>
                  <li>Mesa de los Ocotes</li>
                  <li>Nueva España</li>
                  <li>Valle de la Primavera</li>
                </ul>
              </ul>
              <ul className="list-disc pl-6 leading-6">
                <li>Zapotlanejo</li>
                <ul className='list-disc pl-6 leading-6'>
                  <li>Agua Blanca</li>
                  <li>Balcones de Huisquilco</li>
                  <li>Constitución</li>
                  <li>El Santuario</li>
                  <li>Jardines del Paraíso</li>
                  <li>La Ceja</li>
                  <li>La Cruz</li>
                  <li>San Francisco</li>
                  <li>San Miguelito</li>
                  <li>Tres Flores</li>
                </ul>
              </ul>
            </li>

            <li style={{ marginTop: '5px' }}>
            <span className='font-bold'>Interior:</span>
              <ul className="list-disc pl-6 leading-6">
                <li>Tototlán</li>
                <ul className='list-disc pl-6 leading-6'>
                  <li>Basulto</li>
                  <li>Cabecera Municipal (Centro)</li>
                  <li>Cuesta Chica</li>
                  <li>El Ranchito</li>
                  <li>La Huaracha</li>
                  <li>Las Eras</li>
                  <li>Palo Dulce</li>
                  <li>Ramblas Chico</li>
                  <li>San Isidro</li>
                  <li>Tajo (Cabecera Municipal)</li>
                </ul>
              </ul>
              <ul className="list-disc pl-6 leading-6">
                <li>Poncitlán</li>
                <ul className='list-disc pl-6 leading-6'>
                  <li>La Cuesta de Mezcala</li>
                  <li>Mezcala</li>
                </ul>
              </ul>
              <ul className="list-disc pl-6 leading-6">
                <li>Ocotlán</li>
                <ul className='list-disc pl-6 leading-6'>
                  <li>6 de Noviembre</li>
                  <li>El Nuevo Fuerte</li>
                  <li>Lázaro Cárdenas</li>
                  <li>San Juan</li>
                </ul>
              </ul>
              <ul className="list-disc pl-6 leading-6">
                <li>La Barca</li>
                <ul className='list-disc pl-6 leading-6'>
                  <li>El Abrevadero</li>
                  <li>El Limón</li>
                  <li>La Paz de Ordaz</li>
                  <li>Los Ángeles</li>
                  <li>San Pedro de Ruiz</li>
                  <li>Santa Cecilia</li>
                </ul>
              </ul>
              <ul className="list-disc pl-6 leading-6">
                <li>Jocotepec</li>
                <ul className='list-disc pl-6 leading-6'>
                  <li>El Chante (Chantepec)</li>
                  <li>Potrerillos</li>
                  <li>San Cristóbal</li>
                  <li>San Juan Cosalá</li>
                </ul>
              </ul>
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: `¿Cómo funcionan los Nidos de Lluvia?`,
      children: (
        <p>
          Cuando cae el agua de lluvia en tu techo, se hace correr por canaletas y bajantes al
          sistema, donde el primero paso es el separador de primeras lluvias, llamado Tlaloque, el
          cual intercepta el agua más contaminada y la más limpia la manda al tanque. El segundo
          paso es el filtro de hojas y basura más grande que se hayan alojado en el techo (por ello
          se recomienda tenerlo lo más limpio posible). Después pasa por un reductor de turbulencia
          con el que entra en el tanque de forma tranquila para no revolver los sedimentos que se
          acumulen en el fondo. El cuarto paso es un dosificador de cloro, el cual por medio de un
          flotador libera la cantidad necesaria de cloro para eliminar los microorganismos. , una
          manguera flotante succiona el agua de la parte superior del tanque, donde está el agua más
          limpia. Finalmente, pasa por un último filtro que retira sedimentos en el agua mayores a
          50 micras (un cabello mide alrededor de 100 micras). Luego de todo este proceso es cuando
          el agua pasa al tinaco o la toma que se distribuye en tu hogar, y utilizarla para riego,
          limpieza, excusados, lavabo o regadera.
        </p>
      ),
    },
    {
      title: `¿Podré beber el agua de lluvia captada?`,
      children: (
        <p>
          Para la etapa 2024 del programa, los nidos de Lluvia contarán con un filtro de agua para
          consumo humano.
        </p>
      ),
    },
    {
      title: `¿Cuántos Nidos se instalarán durante 2024?`,
      children: (
        <p>
          Para 2024, se instalarán un total de 3,500 Nidos de Lluvia, distribuidos en 14 municipios de Jalisco seleccionados en la convocatoria de este año.
        </p>
      ),
    },
    {
      title: `¿Qué utilidad tienen los Nidos de Lluvia cuando no es temporada de lluvia?`,
      children: (
        <p>
          En los meses que no son de lluvia en el AMG, los Nidos pueden servir para almacenar agua
          de la toma regular o de pipas; de esta manera se brinda la posibilidad de almacenamiento
          de agua en hogares donde no cuentan con ello.
        </p>
      ),
    },
    {
      title: `¿Qué capacidad tiene el tanque para almacenar el agua de lluvia y cuánto dura  aproximadamente?`,
      children: (
        <p>
          <Link href="https://www.jalisco.gob.mx/es/prensa/noticias/129134">
            Este año habrá dos tipos de sistemas: con tanques de 1,100 y de 2,500 litros,
          </Link>
          {` `} los cuales pueden llenarse entre 7 y 13 veces al año con agua de calidad, dependiendo el tamaño. 
          Si se hace un uso responsable, estos tanques pueden abastecer a una familia de cuatro integrantes entre 1 y 2 semanas en promedio.
        </p>
      ),
    },
    {
      title: `¿Cómo contribuyo a reducir la crisis hídrica al tener un Nido de Lluvia?`,
      children: (
        <p>
          Con este programa, se soluciona de raíz el problema en las colonias que no tienen sistemas
          de almacenaje y año con año padecen la escasez de agua; fenómeno que se intensificará cada
          vez más con la crisis climática.
          <br />
          Además, es la primera acción que abona al cambio de paradigma sobre la gestión del agua en
          Jalisco y en el Área Metropolitana de Guadalajara.
        </p>
      ),
    },
  ];

  return (
    <div className="flex flex-col flex-1 bg-gray-50 min-h-full">
      <div className="flex flex-1 w-full sm:p-6">
        <Container className="flex flex-1 py-7" maxWidth="lg">
          <h1 className="text-2xl font-montserrat-bold text-primary-darker pb-7">
            Preguntas frecuentes
          </h1>
          {questions.map(({ title, children }, index) => (
            <Question key={title} title={`${index + 1}. ${title}`}>
              {children}
            </Question>
          ))}
        </Container>
      </div>
    </div>
  );
};
