import { Autocomplete as MuiAutocomplete } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import clsx from 'clsx';
import { TextField } from './TextField';

const Option = (props, option, { inputValue }) => {
  const matches = match(option.name, inputValue);
  const parts = parse(option.name, matches);
  return (
    <li {...props}>
      <div>
        {parts.map((part, index) => (
          <span
            key={index}
            style={{
              fontWeight: part.highlight ? 700 : 400,
            }}
          >
            {part.text}
          </span>
        ))}
      </div>
    </li>
  );
};

export function Autocomplete({
  className,
  name,
  value,
  onChange,
  onBlur,
  options = [],
  label,
  error,
  helperText,
  noOptionsText,
  multiple,
  required,
  ...props
}) {
  const onValueChange = (event, newValue) => {
    let fieldValueNew = ``;
    if (multiple) {
      const allValues = [];
      newValue.forEach((value) => {
        allValues.push(value.value);
      });
      fieldValueNew = allValues.join(`,`);
    } else {
      fieldValueNew = newValue?.value;
    }
    onChange({ target: { name, value: fieldValueNew } });
  };

  let obj = ``;
  if (multiple) {
    obj = [];
    const valueOptions = value ? value.split(`,`) : [];
    valueOptions.forEach((value) => {
      const find = options.find((o) => `${o.value}` === value) ?? null;
      if (find) {
        obj.push(find);
      }
    });
  } else {
    obj = options.find((o) => o.value === value) ?? null;
  }

  return (
    <div className={clsx(`w-full`, className)}>
      <MuiAutocomplete
        options={options}
        getOptionLabel={(option) => option.name || ``}
        value={obj}
        onChange={onValueChange}
        onBlur={() => {
          if (onBlur) {
            onBlur({ target: { name } });
          }
        }}
        renderInput={({ InputProps, ...params }) => (
          <TextField
            label={label}
            error={error}
            helperText={helperText}
            multiple={multiple}
            required={required}
            autocompleteStyle
            autoComplete="new-password"
            {...InputProps}
            {...params}
          />
        )}
        renderOption={Option}
        noOptionsText={noOptionsText || `Sin resultados`}
        multiple={multiple}
        fullWidth
        {...props}
      />
    </div>
  );
}
