import { useState } from 'react';
import { FormSection } from 'components/FormStepper/FormSection';
import { FormInput } from 'components/FormikComponents';
import { ContainedButton } from 'components/Buttons';
import { Link } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { getByCurp } from 'services/solicitudService';
import CircularProgress from '@mui/material/CircularProgress';
import { danger } from 'utils/Alerts';
import useRecaptcha from 'components/Recaptcha/useRecaptcha';

export const BusquedaCurp = ({ setDisabledFields, handleForward, handleSubmit }) => {
  const { values, setValues, resetForm, setFieldTouched, errors } = useFormikContext();
  const [loading, setLoading] = useState(false);
  const { getRecaptchaToken } = useRecaptcha();

  useEffect(() => {
    // Se reinician los campos deshabilitados
    if (setDisabledFields) {
      setDisabledFields([]);
    }
  }, []);

  const handleSearch = async () => {
    if (values.curp && !errors.curp) {
      setLoading(true);
      const token = await getRecaptchaToken();
      await getByCurp(values.curp, token)
        .then((response) => {
          const { data } = response.data;
          data.solicitante.nacimiento = new Date(`${data.solicitante.nacimiento}T00:00:00-06:00`);
          setValues({ ...values, ...data.solicitante, ...data.contacto }, false);
          setDisabledFields([...Object.keys({ ...data.solicitante, ...data.contacto })]);
          handleSubmit();
        })
        .catch((e) => {
          danger(e.response?.data?.message ?? e.message);
        });
    } else {
      setFieldTouched(`curp`, true, true);
    }
    setLoading(false);
  };

  return (
    <form>
      <FormSection>
        <div className="px-3 w-full font-montserrat-medium text-primary-dark text-base mt-5 mb-7">
          Ingresa tu CURP para precargar tus datos de identificación personal
        </div>
        <FormInput
          className="w-full px-3 sm:w-1/2 lg:w-1/3"
          label="CURP"
          name="curp"
          onKeyPress={(e) => {
            if (e.key === `Enter`) {
              e.preventDefault();
              handleSearch();
            }
          }}
          inputProps={{ style: { textTransform: `uppercase` } }}
        />
        <div className="flex flex-1 flex-wrap justify-center gap-6 mt-7">
          <ContainedButton color="formButton" onClick={handleSearch} disabled={loading}>
            {loading ? <CircularProgress size={24} color="inherit" /> : <>Buscar</>}
          </ContainedButton>
          <ContainedButton
            color="secondaryButton"
            textColor="#7E84A3"
            onClick={() => {
              resetForm();
              handleForward(2);
            }}
            disabled={loading}
          >
            No cuento con CURP
          </ContainedButton>
        </div>
        <div className="px-3 w-full mt-5 mb-7">
          <Link
            sx={{
              color: `#0595F0`,
              textDecorationColor: `#0595F0`,
              fontFamily: `Nutmeg Regular`,
            }}
            href="https://www.gob.mx/curp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Consultar CURP
          </Link>
        </div>
      </FormSection>
    </form>
  );
};
